<template>
  <div class="banner" :class="[height && 'banner--is-' + height]">
    <!-- Banner video -->
    <BrightcovePlayer
      v-if="videoId && brightcove"
      v-show="canPlayVideo"
      data-testid="banner-player"
      :id="`banner-player-${uid}`"
      :isHideControls="true"
      :isAutoPlay="videoAutoPlay"
      :options="{
        videoId: videoId,
        embedId: 'default',
        ...brightcove
      }"
      v-on="$listeners"
      @onCanPlayThrough="onCanPlayThrough"
      class="banner__video"
    />

    <!-- Banner image -->
    <img
      v-if="!canPlayVideo || !brightcove"
      data-testid="banner-image"
      :id="`banner-image-${uid}`"
      :alt="`${imageAlt}`"
      :src="!lazySlide ? bannerImage.url : null"
      :data-src="lazySlide ? bannerImage.url : null"
      :srcset="!lazySlide ? bannerImage.srcset : null"
      :data-srcset="lazySlide ? bannerImage.srcset : null"
      :class="classNames"
      fetchpriority="high"
    />

    <!-- Banner Content -->
    <div
      class="banner__content"
      :class="[
        verticalAlign && 'justify-' + verticalAlign,
        darken ? 'gradient--overlay' : 'banner__content--partial-darken'
      ]"
    >
      <div
        class="container"
        :class="[
          contentAlign && 'text-' + contentAlign,
          `pt-${topPadding}`,
          `pb-${bottomPadding}`,
          `lg:pt-${lgTopPadding}`,
          `lg:pb-${lgBottomPadding}`,
          { 'flex flex-col h-full': verticalAlign !== 'end' }
        ]"
      >
        <div
          :class="{
            'lg:max-w-screen-sm': altBanner,
            'xl:w-10/12': !altBanner,
            'mt-auto': verticalAlign === 'end',
            'mb-auto': verticalAlign === 'start',
            'my-auto': verticalAlign === 'center'
          }"
          class="w-full relative"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import imgSrcset from '@/mixins/imgSrcset'
  import brightcove from '@/mixins/brightcove'
  import BrightcovePlayer from '@/vendor/brightcove/BrightcovePlayer'

  export default {
    mixins: [brightcove, imgSrcset],

    components: {
      BrightcovePlayer
    },

    props: {
      // medium, small
      height: {
        type: String,
        default: ''
      },
      darken: {
        type: Boolean,
        default: true
      },
      contentAlign: {
        type: String,
        default: 'left'
      },
      verticalAlign: {
        type: String,
        default: 'end'
      },
      uid: {
        type: [Number, String],
        required: true
      },
      imageSrc: {
        type: [String, Object],
        required: true
      },
      imageAlt: {
        type: String,
        required: true
      },
      videoAutoPlay: {
        type: Boolean,
        default: false
      },
      lazySlide: {
        type: Boolean,
        default: false
      },
      altBanner: {
        type: Boolean,
        default: false
      },
      videoId: String,
      ctaLink: String,
      ctaCopy: String,
      topPadding: {
        type: String,
        default: '24'
      },
      bottomPadding: {
        type: String,
        default: '24'
      },
      lgTopPadding: {
        type: String,
        default: '20'
      },
      lgBottomPadding: {
        type: String,
        default: '20'
      }
    },

    data() {
      return {
        canPlayVideo: false
      }
    },

    computed: {
      classNames() {
        return [{ 'swiper-lazy': this.lazySlide }, 'banner__img']
      },

      bannerImage() {
        if (this.imageSrc) {
          return this.imgSrcsetBanner(this.imageSrc)
        } else {
          return { url: null, srcset: [] }
        }
      }
    },

    methods: {
      onCanPlayThrough() {
        this.canPlayVideo = true
      }
    }
  }
</script>
