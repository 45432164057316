var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'flex-col',
    'lg:flex-row',
    'items-stretch',
    'mt-14',
    'bg-blue-500',
  ],attrs:{"data-testid":"access-all-course-banner"}},[_c('div',{staticClass:"cat-image w-full lg:w-1/2 bg-opacity-20 banner-image order-first lg:order-last"},[(!_vm.customImage)?_c('img',{staticClass:"w-full h-full object-cover object-center",class:_vm.classNames,attrs:{"loading":"lazy","data-testid":"banner-image","id":`banner-image-${_vm.category.slug}`,"alt":`${_vm.imageAlt}`,"src":!_vm.lazySlide ? _vm.bannerImage.url : null,"data-src":_vm.lazySlide ? _vm.bannerImage.url : null,"srcset":!_vm.lazySlide ? _vm.bannerImage.srcset : null,"data-srcset":_vm.lazySlide ? _vm.bannerImage.srcset : null}}):_c('img',{staticClass:"w-full h-full object-cover object-center",attrs:{"loading":"lazy","alt":"","src":_vm.imageUrl
          ? _vm.imageUrl
          : require('@/assets/images/membership_banner.webp')}})]),_vm._v(" "),_c('div',{staticClass:"banner-content flex flex-col justify-center text-left py-16 gap-6 w-full lg:w-1/2 px-6 md:px-12 mb-12 lg:mb-0"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"mb-1 title-title text-5xl text-white font-serif"},[_vm._v("\n        Access All Courses\n      ")]),_vm._v(" "),_c('div',{staticClass:"font-normal text-lg text-white font-sans",attrs:{"data-testid":"monthly-price"}},[_vm._v("\n        "+_vm._s(_vm.getGeoPrice(_vm.membershipPlan.monthlyPrice))+"/MONTH (billed annually)\n      ")])]),_vm._v(" "),_vm._l((_vm.membershipBannerPoints),function(point,index){return _c('div',{key:index,staticClass:"flex flex-row items-top font-normal text-left"},[_c('Icon',{staticClass:"text-white text-lg mr-3 mt-1",attrs:{"name":"Check"}}),_vm._v(" "),_c('p',{staticClass:"text-white text-xl"},[_vm._v(_vm._s(point))])],1)}),_vm._v(" "),_c('div',{staticClass:"mt-2 text-left"},[_c('div',{staticClass:"inline-block text-center"},[_c('Link',{staticClass:"button button--yellow inlineBlockBtn",attrs:{"data-testid":"start-learning-button","href":_vm.$routes.onboarding()},on:{"click":_vm.trackSubscribeButton}},[_c('span',{staticClass:"flex gap-1 items-center"},[_vm._v("START LEARNING\n            "),_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.3333 5L20 12M20 12L13.3333 19M20 12L4 12","stroke":"#F6F7F9","stroke-width":"1.5","stroke-linecap":"square"}})])])])],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }