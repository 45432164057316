<template>
  <div class="h-screen w-full flex flex-col">
    <MetaTags :metaTags="metaTags" />

    <div class="h-1/2">
      <img loading="lazy"
        class="image-cover max-w-full lazyload object-top"
        src="@/assets/images/backgrounds/Onboarding_image_banner.webp"
      />
    </div>

    <div class="px-4 md:px-10 text-center mx-auto w-full md:w-2/3 h-1/2 lg:h-3/5 flex items-center justify-center flex-col">
      <h1 class="font-serif text-3xl leading-9 lg:text-5xl lg:leading-tight font-medium text-blue-800 mb-5 lg:mb-8 max-w-screen-xs ">Your year of creative learning has begun</h1>

      <p class="text-grayscale-900 text-xl lg:text-2xl font-normal mb-9 lg:mb-12 w-full  mx-auto">Search for your favourite instructor or topic to start learning</p>

      <Link class="button button--blue button--small w-auto md:w-64 mx-auto" :href="$routes.root()">GET STARTED</Link>
    </div>
  </div>
</template>

<script>
  import { Link } from '@inertiajs/inertia-vue'
  import MetaTags from '@/Components/Shared/MetaTags'

  import { metaTags } from '@/mixins/metaTags'
  import currentUser from '@/mixins/currentUser'
  import mixpanel from '@/mixins/mixpanel'
  import gtm from '@/mixins/gtm'

  import Layout from '@/Layouts/ReducedLayout'

  export default {
    layout: Layout,

    name: 'WelcomePage',

    mixins: [
      metaTags,
      currentUser,
      mixpanel,
      gtm
    ],

    components: {
      Link,
      MetaTags
    },

    mounted() {
      this.gtmAddSubscriptionAnnualTracking(currentUser)
    }
  }
</script>
