<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div class="w-full relative py-6 lg:py-11">
      <div class="container pt-0 pb-6 lg:pb-11">
        <!-- Category header -->
        <h3 class="category-header mb-6">Course Library</h3>

        <!-- Primary category filter buttons -->
        <div class="mb-6 filter-buttons-outer-container">
          <div class="filter-buttons-container">
            <div class="flex gap-2 min-w-max pr-4">
              <button
                @click="activeCategory = null"
                :class="[
                  'filter-button',
                  activeCategory === null
                    ? 'bg-blue-800 text-white border-blue-800'
                    : 'bg-white text-blue-800 hover:bg-gray-100 border-gray-300'
                ]"
              >
                ALL
              </button>
              <button
                v-for="category in categories"
                :key="category.id"
                @click="activeCategory = category.id"
                :class="[
                  'filter-button',
                  activeCategory === category.id
                    ? 'bg-blue-800 text-white border-blue-800'
                    : 'bg-white text-blue-800 hover:bg-gray-100 border-gray-300'
                ]"
              >
                {{ category.name.toUpperCase() }}
              </button>
              <button
                @click="activeCategory = 'coming_soon'"
                :class="[
                  'filter-button',
                  activeCategory === 'coming_soon'
                    ? 'bg-blue-800 text-white border-blue-800'
                    : 'bg-white text-blue-800 hover:bg-gray-100 border-gray-300'
                ]"
              >
                COMING SOON
              </button>
            </div>
          </div>
        </div>

        <!-- Results heading and Sort by filter -->
        <div
          class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2 space-y-4 sm:space-y-0"
        >
          <h2 class="results-heading">
            {{ filteredAndSortedCourses.length + (featuredCourse ? 1 : 0) }}
            {{ activeCategoryName }}
            {{
              filteredAndSortedCourses.length + (featuredCourse ? 1 : 0) === 1
                ? 'course'
                : 'courses'
            }}
            available
          </h2>

          <div class="flex items-center self-end">
            <label for="sort-order" class="mr-2 text-sm font-medium text-gray-600">Sort by:</label>
            <select
              id="sort-order"
              v-model="localSortOrder"
              @change="changeSortOrder"
              class="pl-2 pr-8 py-1 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
            >
              <option value="desc">Newest first</option>
              <option value="asc">Oldest first</option>
            </select>
          </div>
        </div>

        <!-- Featured Course -->
        <div v-if="featuredCourse" class="mt-4 mb-12 featured-course-section">
          <div class="bg-blue-100 overflow-hidden">
            <a :href="`/courses/${featuredCourse.slug}`" class="block">
              <div class="flex flex-col md:flex-row">
                <div class="featured-course-image-container">
                  <img
                    v-if="
                      featuredCourse.collectionImagePath ||
                      featuredCourse.imageLargePath ||
                      featuredCourse.imageUrl
                    "
                    :src="
                      featuredCourse.collectionImagePath ||
                      featuredCourse.imageLargePath ||
                      featuredCourse.imageUrl
                    "
                    :alt="featuredCourse.name"
                    class="featured-course-image"
                    @error="handleImageError"
                  />
                  <div v-else class="w-full h-full bg-gray-200 flex items-center justify-center">
                    <span class="text-gray-500">No image available</span>
                  </div>
                  <div v-if="featuredCourse.status === 'coming_soon'" class="overlay-button">
                    COMING SOON
                  </div>
                  <div
                    v-else-if="isRecentlyReleased(featuredCourse.releaseDate)"
                    class="overlay-button"
                  >
                    NEW
                  </div>
                </div>
                <div class="px-12 pt-6 flex flex-col">
                  <h2 class="featured-heading mb-20">FEATURED</h2>
                  <h3 class="featured-course-title mb-1">{{ featuredCourse.name }}</h3>
                  <p class="featured-instructor-name mb-4">
                    <span class="with">with</span> {{ featuredCourse.instructorName }}
                  </p>
                  <p v-if="featuredCourse.lessonCount > 0" class="text-yellow-800 text-sm mb-4">
                    {{ featuredCourse.lessonCount }} Lessons
                  </p>
                  <p
                    class="text-gray-600 mb-4"
                    v-html="truncateDescription(featuredCourse.description, 250)"
                  ></p>
                  <!-- <p class="text-gray-500 text-sm">
                    Released: {{ formatDate(featuredCourse.releaseDate) }}
                  </p> -->
                </div>
              </div>
            </a>
          </div>
        </div>

        <!-- Regular Course Grid -->
        <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          <div
            v-for="course in filteredAndSortedCourses"
            :key="course.id"
            class="bg-white overflow-hidden"
          >
            <a :href="`/courses/${course.slug}`" class="block">
              <div class="relative course-image-container">
                <img
                  v-if="course.collectionImagePath || course.imageLargePath || course.imageUrl"
                  :src="course.collectionImagePath || course.imageLargePath || course.imageUrl"
                  :alt="course.name"
                  class="course-image"
                  @error="e => handleImageError(e, course.name)"
                />
                <div v-else class="w-full h-full bg-gray-200 flex items-center justify-center">
                  <span class="text-gray-500">No image available</span>
                </div>
                <div
                  v-if="course.status === 'early_bird'"
                  class="absolute top-2 right-2 overlay-button"
                >
                  SUBSCRIBERS ONLY
                </div>
                <div
                  v-else-if="course.status === 'coming_soon'"
                  class="absolute top-2 right-2 overlay-button"
                >
                  COMING SOON
                </div>
                <div
                  v-else-if="isRecentlyReleased(course.releaseDate)"
                  class="absolute top-2 right-2 overlay-button"
                >
                  NEW
                </div>
              </div>
              <div class="p-2 sm:p-4 text-center">
                <div class="course-title-container">
                  <h3 class="course-title">{{ course.name }}</h3>
                </div>
                <p class="instructor-name text-xs sm:text-sm">
                  <span class="with">with</span> {{ course.instructorName }}
                </p>
                <p
                  v-if="course.lessonCount > 0"
                  class="text-yellow-800 text-xs sm:text-sm mb-1 sm:mb-2 hidden sm:block"
                >
                  {{ course.lessonCount }} Lessons
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import MetaTags from '@/Components/Shared/MetaTags'
  import { Inertia } from '@inertiajs/inertia'

  export default defineComponent({
    components: {
      MetaTags
    },
    props: {
      courses: {
        type: Array,
        required: true
      },
      categories: {
        type: Array,
        required: true
      },
      instructors: {
        type: Array,
        required: true
      },
      comingSoonCount: {
        type: Number,
        required: true
      },
      sortOrder: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const activeCategory = ref(null)
      const localSortOrder = ref(props.sortOrder)

      // Select the featured course based on the active category
      const featuredCourse = computed(() => {
        let candidateCourses
        if (activeCategory.value === null) {
          candidateCourses = props.courses
        } else if (activeCategory.value === 'coming_soon') {
          candidateCourses = props.courses.filter(course => course.status === 'coming_soon')
        } else {
          candidateCourses = props.courses.filter(
            course =>
              course.categoryIds.includes(activeCategory.value) &&
              (course.status === 'published' || course.status === 'coming_soon')
          )
        }

        // Sort candidate courses by release date (newest first) and select the first one
        return (
          candidateCourses.sort((a, b) => {
            const dateA = a.releaseDate ? new Date(a.releaseDate) : new Date(0)
            const dateB = b.releaseDate ? new Date(b.releaseDate) : new Date(0)
            return dateB - dateA
          })[0] || null
        )
      })

      // Filter courses based on the active category
      const filteredCourses = computed(() => {
        return props.courses.filter(course => {
          if (activeCategory.value === null) {
            return ['published', 'coming_soon', 'early_bird'].includes(course.status)
          } else if (activeCategory.value === 'coming_soon') {
            return course.status === 'coming_soon'
          } else {
            return (
              course.categoryIds.includes(activeCategory.value) &&
              ['published', 'coming_soon', 'early_bird'].includes(course.status)
            )
          }
        })
      })

      // Sort the filtered courses, excluding the featured course
      const filteredAndSortedCourses = computed(() => {
        let coursesToSort = filteredCourses.value.filter(course => course !== featuredCourse.value)

        return coursesToSort.sort((a, b) => {
          const dateA = a.releaseDate ? new Date(a.releaseDate) : new Date(0)
          const dateB = b.releaseDate ? new Date(b.releaseDate) : new Date(0)
          if (localSortOrder.value === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        })
      })

      const coursesInCategory = categoryId => {
        return props.courses.filter(course => course.categoryIds.includes(categoryId)).length
      }

      const changeSortOrder = () => {
        Inertia.get('/courses', { sort_order: localSortOrder.value }, { preserveState: true })
      }

      watch(
        () => props.sortOrder,
        newValue => {
          localSortOrder.value = newValue
        }
      )

      const metaTags = computed(() => ({
        title: 'All Courses | Create Academy',
        meta: [
          {
            name: 'description',
            content: 'Explore our wide range of courses taught by expert instructors.'
          }
        ]
      }))

      const activeCategoryName = computed(() => {
        if (activeCategory.value === null) {
          return ''
        } else if (activeCategory.value === 'coming_soon') {
          return 'Coming Soon'
        } else {
          const category = props.categories.find(cat => cat.id === activeCategory.value)
          return category ? category.name : ''
        }
      })

      console.log('Courses:', props.courses)
      console.log('Instructors:', props.instructors)

      // Add a function to format the date
      const formatDate = dateString => {
        if (!dateString) return 'Not released yet'
        const date = new Date(dateString)
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      }

      const isRecentlyReleased = dateString => {
        if (!dateString) return false
        const releaseDate = new Date(dateString)
        const now = new Date()
        const ninetyDaysAgo = new Date(now.setDate(now.getDate() - 90))
        return releaseDate >= ninetyDaysAgo
      }

      const formatDuration = minutes => {
        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60
        return `${hours}h ${remainingMinutes}m`
      }

      // Update the truncateDescription function to handle HTML
      const truncateDescription = (description, maxLength = 150) => {
        if (!description) return ''

        // Strip HTML tags
        const strippedDescription = description.replace(/<[^>]*>/g, '')

        if (strippedDescription.length <= maxLength) return description

        // Truncate the stripped description
        const truncated = strippedDescription.substr(0, maxLength).trim()

        // Add ellipsis and close any open HTML tags
        return truncated + '...'
      }

      const handleImageError = event => {
        console.error('Image failed to load:', event.target.src)
      }

      // Add this function to handle popstate events
      const handlePopState = event => {
        try {
          // First try the modern approach
          if (event.state?.url) {
            Inertia.visit(event.state.url, {
              preserveState: true,
              preserveScroll: true
            })
          }
          // Fallback for older versions or when state is missing
          else if (window.location.href) {
            Inertia.visit(window.location.href, {
              preserveState: true,
              preserveScroll: true
            })
          }
          // Last resort
          else {
            window.location.reload()
          }
        } catch (error) {
          // If anything goes wrong, force a reload
          console.error('Navigation error:', error)
          window.location.reload()
        }
      }

      onMounted(() => {
        window.addEventListener('popstate', handlePopState)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('popstate', handlePopState)
      })

      return {
        activeCategory,
        filteredAndSortedCourses,
        coursesInCategory,
        metaTags,
        localSortOrder,
        changeSortOrder,
        activeCategoryName,
        formatDate,
        isRecentlyReleased,
        featuredCourse,
        formatDuration,
        truncateDescription,
        handleImageError,
        handlePopState
      }
    },
    directives: {
      clamp: {
        mounted(el, binding) {
          const lines = binding.value || 3
          el.style.overflow = 'hidden'
          el.style.textOverflow = 'ellipsis'
          el.style.display = '-webkit-box'
          el.style.webkitLineClamp = lines
          el.style.webkitBoxOrient = 'vertical'
        }
      }
    }
  })
</script>

<style scoped>
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .course-title-container {
    min-height: 3em; /* Adjust this value as needed for two lines */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }

  .course-title {
    font-family: 'Saol CA', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    width: 100%;
    text-wrap: balance;
  }

  .instructor-name {
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
    font-family: 'Brandon Grotesque', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .instructor-name .with {
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
    font-family: 'Saol CA', serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
  }

  .course-title-container {
    height: auto;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  /* You can add a custom class for the image if you want more control */
  .course-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  /* You can add a specific style for both "COMING SOON" and "NEW" text if needed */
  .coming-soon-text,
  .new-course-text {
    text-transform: uppercase;
  }

  .course-image-container {
    width: 100%;
    padding-top: 113.67%; /* (474 / 417) * 100% */
    position: relative;
    overflow: hidden;
  }

  .featured-course-image-container {
    position: relative;
    width: 100%;
    padding-top: 75%; /* Back to original aspect ratio for mobile */
  }

  .featured-course-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .featured-course-title {
    font-family: 'Saol CA', serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
  }

  .featured-instructor-name {
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
    font-family: 'Brandon Grotesque', sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }

  .featured-instructor-name .with {
    font-family: 'Saol CA', serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    text-transform: none;
  }

  .featured-course-section {
    background: var(--blue-100, #f6f7f9);
  }

  .featured-heading {
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
    text-align: left;
    font-family: 'Brandon Grotesque', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }

  .category-header {
    color: var(--blue-800, #0e1e38);
    text-align: left;
    font-family: 'Saol CA', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px; /* 125% */
    margin-bottom: 1.5rem; /* This is equivalent to the mb-6 class */
    padding-top: 0.5rem; /* Reduced from 1rem to 0.5rem */
  }

  .results-heading {
    text-align: center;
    font-family: 'Brandon Grotesque', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .filter-buttons-outer-container {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    overflow: hidden;
  }

  .filter-buttons-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 1rem; /* Add some bottom padding to account for hidden scrollbar */
  }

  .filter-buttons-container::-webkit-scrollbar {
    display: none;
  }

  .filter-button {
    flex-shrink: 0;
    padding: 0.5rem 1rem;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    border: 1px solid;
    border-radius: 0;
    outline: none;
    text-align: center;
    font-family: 'Brandon Grotesque', sans-serif;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transition:
      background-color 0.3s,
      color 0.3s;
  }

  .filter-button:focus {
    outline: none;
    box-shadow: none;
  }

  .filter-button.bg-blue-800 {
    color: white;
  }

  @media (min-width: 768px) {
    .filter-buttons-outer-container {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
    }

    .filter-buttons-container {
      overflow-x: visible;
      padding-bottom: 0;
    }

    .filter-button {
      flex-shrink: 1;
    }

    .featured-course-image-container {
      width: 50%;
      padding-top: 0;
      height: 500px; /* Keep the desktop height */
    }

    .featured-course-section > div > a > div > div:last-child {
      width: 50%;
      padding: 48px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 80px;
    }
  }

  @media (min-width: 640px) {
    .course-title-container {
      min-height: 3.6em; /* Adjust for larger font size */
    }

    .course-title {
      font-size: 30px;
    }
  }

  .overlay-button {
    color: var(--Blues-Oxford, var(--blue-800, #0e1e38));
    text-align: center;
    font-family: 'Brandon Grotesque', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.1px;
    text-transform: uppercase;
    background-color: white;
    padding: 4px 8px;
    position: absolute;
    top: 8px;
    right: 8px;
  }
</style>
