<template>
  <div class="container mx-auto px-0 md:px-4 py-8">
    <MetaTags :metaTags="metaTags" />

    <!-- Title & Subhead -->
    <section class="text-center px-4 md:px-0 pb-24">
      <div class="mx-auto w-full lg:w-1/2 mb-8">
        <h1 class="title-title mb-2 text-blue-800">
          Build your creative confidence, one lesson at a time
        </h1>
        <div
          class="text-lg leading-6 lg:text-2xl font-light lg:leading-8 max-w-xl mx-auto"
        >
          Get unlimited access to over 1,000 lessons taught by experts across
          interiors, gardening, craft, and food.
        </div>
      </div>
      <div class="h-[20rem]">
        <img loading="lazy"
          :data-src="this.heroOne.url"
          :srcset="this.heroOne.srcset"
          :alt="$I18n.t('banner_image_alt', i18nScope)"
          class="h-[20rem] lazyload"
        />
      </div>
      <div class="max-w-2xl mx-auto mt-6 lg:mt-16 text-center">
        <div
          class="font-normal text-base lg:text-lg text-blue-800 mb-2 lg:mb-3 tracking-wide lg:tracking-wider"
          v-html="membershipPlan.description"
        ></div>
        <div
          class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
        >
          <Link
            class="button button--yellow inlineBlockLink w-80 max-w-full"
            @click="onCheckoutClick(1)"
            :href="currentUser? $routes.membership_checkout():$routes.onboarding()"
          >
            <span class="text-base"
              >
              SUBSCRIBE
              <!-- Join for
              {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month -->
              </span
            >
          </Link>
          <Link
            class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full"
            @click="onGiftClick(1)"
            :href="$routes.gifts() + '#membershipSection'"
          >
            <span class="text-base">GIFT SUBSCRIPTION </span>
          </Link>
        </div>
        <!-- <div class="font-light text-blue-800 text-xs lg:text-base">
          (Billed annually)
        </div> -->
      </div>
    </section>

    <!-- Video Player -->
    <section class="bg-yellow-100 py-20 w-full">
      <VideoPlayer
        data-testid="instructor-video-player"
        class="mb-4 lg:mb-8"
        :isAutoPlay="false"
        :options="{
          accountId: 6222962662001,
          playerId: brightcove.lessonPlayer.playerId,
          videoId: 6322144530112,
          embedId: 'default',
        }"
        :mixpanel="{ type: 'landing', videoId: 6322144530112 }"
      />
    </section>

    <!-- Feature blocks -->
    <section class="p-6 py-16 w-full mx-auto flex flex-col bg-blue-100">
      <div class="mx-auto max-w-lg mb-12 text-center">
        <div class="title-largest text-blue-800">Unlock your creativity</div>
        <div class="font-normal text-2xl mt-4">
          Over <span class="text-yellow-800 font-bold">150 hours</span> of
          immersive learning to transform the way you live
        </div>
      </div>

      <!-- Block one -->
      <div class="flex flex-col lg:flex-row items-center w-full mb-8 lg:mb-0">
        <div class="w-full lg:w-1/2 p-4 lg:p-6">
          <div class="w-full">
            <img loading="lazy"
              :data-src="this.featureBlockOne.url"
              :srcset="this.featureBlockOne.srcset"
              :alt="$I18n.t('banner_image_alt', i18nScope)"
              class="image-cover lazyload"
            />
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-4 lg:p-6 text-center">
          <img loading="lazy"
            :data-src="this.createStar"
            alt="CA"
            class="mx-auto lazyload mb-6"
          />
          <div class="title-largest">Unlimited access</div>
          <div
            class="uppercase text-gray-600 text-md font-normal mt-1 tracking-wide"
          >
            To all courses and resources
          </div>
        </div>
      </div>

      <!-- Block two -->
      <div
        class="flex flex-col-reverse lg:flex-row items-center w-full mb-8 lg:mb-0"
      >
        <div class="w-full lg:w-1/2 p-4 lg:p-6 text-center">
          <img loading="lazy"
            :data-src="this.createStar"
            alt="CA"
            class="mx-auto lazyload mb-6"
          />
          <div class="title-largest">Expert instruction</div>
          <div
            class="uppercase text-gray-600 text-md font-normal mt-1 tracking-wide"
          >
            From the best designers, makers, growers & Chefs
          </div>
        </div>
        <div class="w-full lg:w-1/2 p-4 lg:p-6">
          <div class="w-full">
            <img loading="lazy"
              :data-src="this.featureBlockTwo.url"
              :srcset="this.featureBlockTwo.srcset"
              :alt="$I18n.t('banner_image_alt', i18nScope)"
              class="image-cover lazyload"
            />
          </div>
        </div>
      </div>

      <!-- Block three -->
      <div class="flex flex-col lg:flex-row items-center w-full mb-8 lg:mb-0">
        <div class="w-full lg:w-1/2 p-4 lg:p-6">
          <div class="w-full">
            <img loading="lazy"
              :data-src="this.featureBlockThree.url"
              :srcset="this.featureBlockThree.srcset"
              :alt="$I18n.t('banner_image_alt', i18nScope)"
              class="image-cover lazyload"
            />
          </div>
        </div>
        <div class="w-full lg:w-1/2 p-4 lg:p-6 text-center">
          <img loading="lazy"
            :data-src="this.createStar"
            alt="CA"
            class="mx-auto lazyload mb-6"
          />
          <div class="title-largest">New content</div>
          <div
            class="uppercase text-gray-600 text-md font-normal mt-1 tracking-wide"
          >
            Added each month to help you develop your skills
          </div>
        </div>
      </div>

      <div class="max-w-2xl mx-auto mt-6 lg:mt-16 text-center">
        <div
          class="font-normal text-base lg:text-lg text-blue-800 mb-2 lg:mb-3 tracking-wide lg:tracking-wider"
          v-html="membershipPlan.description"
        ></div>
        <div
          class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-3"
        >
          <Link
            class="button button--yellow inlineBlockLink w-80 max-w-full"
            @click="onCheckoutClick(2)"
            :href="currentUser? $routes.membership_checkout():$routes.onboarding()"
          >
            <span class="text-base"
              >
              <!-- Join for
              {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month -->
              SUBSCRIBE
              </span
            >
          </Link>
          <Link
            class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full"
            @click="onGiftClick(2)"
            :href="$routes.gifts() + '#membershipSection'"
          >
            <span class="text-base">GIFT SUBSCRIPTION </span>
          </Link>
        </div>
        <!-- <div class="font-light text-blue-800 text-xs lg:text-base">
          (Billed annually)
        </div> -->
      </div>
    </section>

    <!-- Join our community -->
    <section class="p-6 py-16 w-full mx-auto flex flex-col">
      <div class="mx-auto max-w-lg mb-12 text-center">
        <div class="title-largest text-blue-800">
          Join our community of over
          <span class="text-yellow-800">100,000</span> learners
        </div>
      </div>
      <div class="lg:flex lg:items-start mx-auto w-full">
        <!-- Block one -->
        <div class="w-full mb-8 lg:mb-0 lg:w-1/3 px-4">
          <div class="w-full h-64">
            <img loading="lazy"
              :data-src="this.communityBlockOne.url"
              :srcset="this.communityBlockOne.srcset"
              :alt="$I18n.t('banner_image_alt', i18nScope)"
              class="image-cover lazyload"
            />
          </div>
          <div
            class="mt-4 title-gigantic transform rotate-180 text-right tracking-tighter mb-0"
          >
            ,,
          </div>
          <div class="-mt-8 lg:-mt-6 title-large">
            I would never have been able to make this if it weren't for Create
            Academy.
          </div>
          <div
            class="uppercase text-yellow-800 text-md font-normal mt-0 lg:mt-2 tracking-wide"
          >
            From Nicola
          </div>
        </div>
        <!-- Block two -->
        <div class="w-full mb-8 lg:mb-0 lg:w-1/3 px-4">
          <div class="w-full h-64">
            <img loading="lazy"
              :data-src="this.communityBlockTwo.url"
              :srcset="this.communityBlockTwo.srcset"
              :alt="$I18n.t('banner_image_alt', i18nScope)"
              class="image-cover lazyload"
            />
          </div>
          <div
            class="mt-4 title-gigantic transform rotate-180 text-right tracking-tighter mb-0"
          >
            ,,
          </div>
          <div class="-mt-8 lg:-mt-6 title-large">
            Create Academy's online courses are brilliant for me. It allows the
            opportunity of learning from some wonderfully talented people, from
            the other side of the world, at a time to suit me.
          </div>
          <div
            class="uppercase text-yellow-800 text-md font-normal mt-0 lg:mt-2 tracking-wide"
          >
            From DI
          </div>
        </div>
        <!-- Block three -->
        <div class="w-full mb-8 lg:mb-0 lg:w-1/3 px-4">
          <div class="w-full h-64">
            <img loading="lazy"
              :data-src="this.communityBlockThree.url"
              :srcset="this.communityBlockThree.srcset"
              :alt="$I18n.t('banner_image_alt', i18nScope)"
              class="image-cover lazyload"
            />
          </div>
          <div
            class="mt-4 title-gigantic transform rotate-180 text-right tracking-tighter mb-0"
          >
            ,,
          </div>
          <div class="-mt-8 lg:-mt-6 title-large">
            Such great value because you come out with loads of inspiring
            projects to get you started and actionable advice.
          </div>
          <div
            class="uppercase text-yellow-800 text-md font-normal mt-0 lg:mt-2 tracking-wide"
          >
            From Bert
          </div>
        </div>
      </div>
    </section>

    <!-- Our story -->
    <section class="bg-yellow-100 px-10 py-16 w-full mx-auto flex flex-col">
      <div class="lefty">
        <div class="title-largest mb-2">Our story</div>
        <div class="mb-6 max-w-lg text-xl font-normal">
          At Create Academy we believe everyone is creative, and we're on a
          mission to connect you to your creativity.
        </div>
      </div>

      <div class="mx-auto max-w-xl undent my-4 text-xl font-normal">
        Over the past four years, we've worked with more than 40 world-renowned
        makers, designers, gardeners, photographers and chefs to make this
        possible. We're passionate about giving access to expertise in
        industries that have been traditionally guarded, building a world of
        creativity where you can learn new skills, develop your confidence and
        find inspiration from the best.
      </div>

      <div class="mx-auto max-w-xl my-4">
        <div class="title-largest mb-2 text-yellow-800">
          Now, we're offering all access membership. All courses. All resources.
          All the learning. In one membership.
        </div>
      </div>

      <div class="mx-auto max-w-xl my-4">
        <div class="title-largest mb-2 text-yellow-800">
          If we are all creative, then creativity is for everyone.
        </div>
        <div class="mt-6">
          <Link
            data-testid="flex pb-1 border-b border-yellow-800"
            class="font-normal text-gray-800 border-b border-yellow-800 text-xl"
            @click="onCheckoutClick(3)"
            :href="$routes.about_us()"
          >
            <span class="">Find out more</span>
            <Icon name="Arrow" class="text-lg font-semibold ml-1" />
          </Link>
        </div>
      </div>
    </section>
    <section
      class="bg-blue-500 h-96 p-6 w-full mx-auto flex flex-col justify-center items-center"
    >
      <div class="mx-auto max-w-2xl text-center">
        <div
          class="text-xl lg:title-largester mb-6 text-white"
          v-html="membershipPlan.description"
        ></div>

        <div
          class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
        >
          <Link
            class="button button--yellow inlineBlockLink w-80 max-w-full"
            @click="onCheckoutClick(4)"
            :href="currentUser? $routes.membership_checkout():$routes.onboarding()"
          >
            <span class="text-base"
              >
              <!-- Join for
              {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month -->
              SUBSCRIBE
              </span
            >
          </Link>
          <Link
            class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full group"
            @click="onGiftClick(3)"
            :href="$routes.gifts() + '#membershipSection'"
          >
            <span class="text-base text-white group-hover:text-yellow-800"
              >GIFT SUBSCRIPTION </span
            >
          </Link>
        </div>
        <!-- <div class="mt-2 font-light text-lg text-white">(Billed annually)</div> -->
      </div>
    </section>

    <!-- FAQs -->
    <section
      class="bg-white py-20 px-10 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto"
    >
      <SerifHeader
        title="FAQs"
        headingLevel="h2"
        content-align="center"
        titleSize="large"
        color="green"
      />
      <dl class="space-y-8 mb-6">
        <div v-for="faq in faqs" :key="faq.id">
          <dt class="font-serif font-medium text-2xl mb-2">
            {{ faq.question }}
          </dt>
          <dd class="font-normal text-xl">{{ faq.answer }}</dd>
        </div>
      </dl>
    </section>
  </div>
</template>

<script>
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import Icon from '@/Components/Shared/Icon'
import { metaTags } from '@/mixins/metaTags'
import MetaTags from '@/Components/Shared/MetaTags'
import monetize from '@/mixins/monetize'
import currentUser from '@/mixins/currentUser'
import { Link } from '@inertiajs/inertia-vue'
import VideoPlayer from '@/Components/Shared/VideoPlayer'
import mixpanel from '@/mixins/mixpanel'
import abTest from '@/mixins/abTest'

export default {
  mixins: [metaTags, monetize, currentUser, mixpanel, abTest],
  components: {
    SerifHeader,
    Icon,
    MetaTags,
    Link,
    VideoPlayer
  },
  props: {
    membershipPlan: {
      type: Object,
      required: true
    },
    brightcove: {
      type: Object,
      required: true
    },
    geoProps: {
      type: Object,
      required: true
    }
  },
  methods: {
    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },
    getGeoPrice(gbpBasePrice) {
      return this.geoMonetize(
        gbpBasePrice * this.geoProps.exchangeRate,
        this.geoProps.currencySymbol,
        2
      )
    },
    onCheckoutClick(buttonOrder) {
      this.trackEvent('membership_checkout', {
        userType: this.getUserType(),
        buttonOrder: buttonOrder
      })
      this.trackEvent('subscribe_button', {
        userType: this.getUserType(),
        page: window.location.pathname,
        component: 'Promo_uk Page CTA'
      })
    },
    onGiftClick(buttonOrder) {
      this.trackEvent('membership_gift', {
        userType: this.getUserType(),
        buttonOrder: buttonOrder
      })
    }
  },
  computed: {
    // AB TEST SETUP
    targetTest() {
      return 'membership_landing'
    },
    faqs() {
      return [
        {
          id: 1,
          question: 'When and where do Create Academy courses take place?',
          answer:
            'All of our courses are online, meaning that you are able to watch them whenever and wherever suits you best. There is no beginning or finish date and they’re completely self-paced so that you can stop, start and pause each lesson to fit around your own schedule.'
        },
        {
          id: 2,
          question: 'How will I get access to the courses?',
          answer:
            'You’ll access our full course library via your Create Academy account - so you’ll need to log in or create an account when joining all access membership.'
        },
        {
          id: 3,
          question: 'How long does my membership last?',
          answer: 'Membership renews on an annual basis.'
        },
        {
          id: 4,
          question: 'When will I be charged?',
          answer:
            'On the day you join membership, you\'ll pay upfront for the upcoming year, and your renewal date will be 12 months from the day you join.'
        },
        {
          id: 5,
          question: 'What if I\'ve previously purchased an individual course?',
          answer:
            'Any courses you already own before joining membership will not be affected, and you can continue to enjoy them as before. If you choose to end your membership you\'ll retain access to any courses you purchased before joining membership.'
        },
        {
          id: 6,
          question: 'How do I cancel my membership?',
          answer:
            'Your membership comes with a 30 day money back guarantee, so within 30 days of purchase you can cancel and get a refund by simply emailing info@createacademy.com. Similarly, if you would like to cancel so your membership doesn\'t automatically renew after 12 months, please email info@createacademy.com at any time.'
        }
      ]
    }
  },
  mounted() {
    this.trackEvent('membership_landing_view', {
      userType: this.getUserType(),
      abTest: this.activeAbTest(this.targetTest) // associate this event with the active AB test
    })
  },
  created() {
    this.i18nScope = { scope: 'pages.membership' }

    this.heroOne = {
      url: require('@/assets/images/landing/mem/member banner_rita.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/member banner_rita.webp'),
        medium: require('@/assets/images/landing/mem/member banner_rita.webp'),
        small: require('@/assets/images/landing/mem/member banner_rita.webp')
      }
    }

    this.communityBlockOne = {
      url: require('@/assets/images/landing/mem/wreath.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/wreath.webp'),
        medium: require('@/assets/images/landing/mem/wreath.webp'),
        small: require('@/assets/images/landing/mem/wreath.webp')
      }
    }

    this.communityBlockTwo = {
      url: require('@/assets/images/landing/mem/Headboard.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/Headboard.webp'),
        medium: require('@/assets/images/landing/mem/Headboard.webp'),
        small: require('@/assets/images/landing/mem/Headboard.webp')
      }
    }

    this.communityBlockThree = {
      url: require('@/assets/images/landing/mem/Curtains.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/Curtains.webp'),
        medium: require('@/assets/images/landing/mem/Curtains.webp'),
        small: require('@/assets/images/landing/mem/Curtains.webp')
      }
    }

    this.featureBlockOne = {
      url: require('@/assets/images/landing/mem/featureOne.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/featureOne.webp'),
        medium: require('@/assets/images/landing/mem/featureOne.webp'),
        small: require('@/assets/images/landing/mem/featureOne.webp')
      }
    }

    this.featureBlockTwo = {
      url: require('@/assets/images/landing/mem/featureTwo.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/featureTwo.webp'),
        medium: require('@/assets/images/landing/mem/featureTwoMobile.webp'),
        small: require('@/assets/images/landing/mem/featureTwoMobile.webp')
      }
    }

    this.featureBlockThree = {
      url: require('@/assets/images/landing/mem/featureThree.webp'),
      sizes: {
        large: require('@/assets/images/landing/mem/featureThree.webp'),
        medium: require('@/assets/images/landing/mem/featureThree.webp'),
        small: require('@/assets/images/landing/mem/featureThree.webp')
      }
    }

    this.createStar = require('@/assets/images/CaStar.svg')
  }
}
</script>
