<template>
  <div class="section--overlay">
    <div class="container py-12 lg:py-24 relative">
      <div class="flex flex-col gap-4 lg:gap-12">
        <div class="flex flex-col mb-2 lg:mb-0 gap-1 lg:gap-3">
          <h2 class=" text-3xl lg:text-4xl font-serif font-medium text-black text-center">Find The Inspiration You Need To Flourish</h2>
          <p class="text-center text-base lg:text-xl text-black font-normal">We’re here to help you unlock your creativity.</p>
        </div>
        <div data-testid="home-about-section-video" class="flex flex-col w-full lg:w-10/12 mx-auto">
          <VideoPlayer
            data-testid="home-about-section-video-player"
            class=""
            :isAutoPlay="false"
            :options="{
              accountId: 6222962662001,
              playerId: '3BqAJwkg7',
              videoId: 6322144530112,
              embedId: 'default',
            }"
            :mixpanel="{ type: 'landing', videoId: 6322144530112 }"
          />
        </div>
        <div class="grid grid-cols-12 gap-5 lg:gap-9">
          <div class="col-span-12 lg:col-span-4">
            <div class="mb-2 lg:mb-6 text-center">
              <img width="60" height="80" class="h-10 lg:h-20 mx-auto" src="@/assets/images/landing/illustrations/illustration-img1.svg" alt="Variety Of Courses" />
            </div>
            <h4 class=" font-serif text-black mb-1 lg:mb-2 text-xl lg:text-3xl font-medium text-center">A Huge Variety Of<br class="hidden lg:block" /> Courses</h4>
            <p class="text-center text-sm lg:text-base text-black font-normal w-96 max-w-full mx-auto text-balance">Explore countless creative paths in home and garden, from interior design to craft and beyond.</p>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <div class="mb-2 lg:mb-6 text-center">
              <img width="60" height="80" class="h-10 lg:h-20 mx-auto" src="@/assets/images/landing/illustrations/illustration-img2.svg" alt="Guidance" />
            </div>
            <h4 class=" font-serif text-black mb-1 lg:mb-2 text-xl lg:text-3xl font-medium text-center">Guidance From True<br class="hidden lg:block" /> Experts</h4>
            <p class="text-center text-sm lg:text-base text-black font-normal w-96 max-w-full mx-auto text-balance">Learn directly from industry legends who've mastered their craft through years of experience.</p>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <div class="mb-2 lg:mb-6 text-center">
              <img width="60" height="80" class="h-10 lg:h-20 mx-auto" src="@/assets/images/landing/illustrations/illustration-img3.svg" alt="Transformational Advice" />
            </div>
            <h4 class=" font-serif text-black mb-1 lg:mb-2 text-xl lg:text-3xl font-medium text-center">Practical, Transformational Advice</h4>
            <p class="text-center text-sm lg:text-base text-black font-normal w-96 max-w-full mx-auto text-balance">Turn expert knowledge into real-world skills that uplift your everyday life and experiences.</p>
          </div>
        </div>
        <div class="mx-auto text-center">
          <ButtonLink
            data-testid="start-learning-link"
            href="/get-started"
            class=" mx-auto w-full xs:w-auto md:w-96 max-w-full"
            variant="red"
            >
            START LEARNING
          </ButtonLink>
        </div>
    </div>
    </div>

  </div>
</template>

<script>
import VideoPlayer from '@/Components/Shared/VideoPlayer'
import ButtonLink from '@/Components/Shared/ButtonLink'
export default {

  components: {
    VideoPlayer,
    ButtonLink
  },

  created() {
    this.i18nScope = { scope: 'pages.home.section_titles' }
  }
}
</script>
