<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div data-testid="banner-section">
      <img
        uid="about"
        :src="bannerImage.url"
        :data-src="bannerImage.url"
        :srcset="bannerImage.url"
        :data-srcset="bannerImage.url"
        :alt="$I18n.t('about_banner', i18nScope)"
        class="w-full object-cover object-top"
        fetchpriority="high"
      />
    </div>

    <template v-for="item in content">
      <div :key="item.about.id">
        <div class="relative w-full h-auto">
          <div class="relative w-full lg:absolute bg-neutral-200 lg:bg-transparent lg:-top-72">
            <div
              class="hidden lg:block bg-neutral-200 absolute left-0 top-0 lg:w-4/5 2xl:w-3/4 blockHeight"
            ></div>
            <div class="container py-8 lg:py-12 relative">
              <h4
                class="w-72 lg:w-auto mx-auto lg:mx-0 font-serif text-4xl text-center lg:text-left lg:text-6xl text-blue-800 font-medium mb-3 lg:mb-4"
              >
                {{ item.about.title }}
              </h4>
              <p
                class="w-full max-w-3xl text-base tracking-wide lg:tracking-normal lg:text-2xl font-normal text-center lg:text-left lg:pl-8 lg:border-l-2 leading-7 lg:leading-9 lg:border-grayscale-700 text-black lg:text-grayscale-700"
              >
                {{ item.about.description }}
              </p>
            </div>
          </div>
        </div>

        <div class="container p-0 lg:px-16 lg:py-20 lg:pb-16">
          <div data-testid="about" class="flex flex-col lg:w-10/12 mx-auto">
            <VideoPlayer
              data-testid="about-video-player"
              class="mb-8"
              :isAutoPlay="false"
              :options="{
                accountId: brightcove.lessonPlayer.accountId,
                playerId: brightcove.lessonPlayer.playerId,
                videoId: 6322144530112,
                embedId: 'default'
              }"
              :mixpanel="{
                type: 'about-us',
                videoId: 6322144530112
              }"
            />
          </div>
        </div>

        <div class="container lg:pb-20 lg:pt-0">
          <div
            data-testid="our-story"
            class="flex flex-col items-center gap-5 lg:gap-6 lg:w-10/12 mx-auto"
          >
            <h4 class="font-serif text-blue-800 text-4xl lg:text-5xl font-medium text-center">
              {{ item.story.title }}
            </h4>
            <p
              class="text-black text-base leading-7 tracking-wide lg:tracking-normal lg:text-2xl font-normal text-center lg:leading-9"
            >
              {{ item.story.para_one }}
            </p>
            <p
              class="text-black text-base leading-7 tracking-wide lg:tracking-normal lg:text-2xl font-normal text-center lg:leading-9"
            >
              {{ item.story.para_two }}
            </p>
            <p
              class="text-black text-base leading-7 tracking-wide lg:tracking-normal lg:text-2xl font-normal text-center lg:leading-9"
            >
              {{ item.story.para_three }}
            </p>
            <img
              width="165"
              height="84"
              alt="Juliane's Signature"
              class="mx-auto w-32 lg:w-44 lg:my-3"
              src="@/assets/images/backgrounds/signature.png"
            />
            <p
              class="text-black text-base leading-7 tracking-wide lg:tracking-normal lg:text-2xl font-medium text-center lg:leading-9"
            >
              -{{ item.story.founder }}
            </p>
          </div>
        </div>

        <div class="z-10 relative container px-2.5 py-0 lg:px-16 lg:py-0">
          <div
            data-testid="our-story"
            class="flex flex-col items-center gap-5 lg:gap-6 lg:w-10/12 mx-auto"
          >
            <img
              uid="founder"
              :src="founderImage.url"
              :data-src="founderImage.url"
              :srcset="founderImage.url"
              :data-srcset="founderImage.url"
              :alt="$I18n.t('founder_banner', i18nScope)"
              class="h-auto w-full object-cover"
              fetchpriority="high"
            />
          </div>
        </div>

        <div class="relative z-0">
          <div class="custom_background bg-green-300"></div>
          <div class="relative container pt-20 pb-10 lg:py-32">
            <h4
              class="font-serif mx-auto w-52 sm:w-auto text-blue-800 text-4xl lg:text-5xl font-medium text-center mb-12 lg:mb-24"
            >
              Our Journey So Far
            </h4>
            <div
              class="flex flex-col lg:flex-row items-stretch justify-between gap-6 lg:gap-8 relative"
            >
              <div class="hidden lg:block absolute bottom-0 dividerWidth h-px bg-blue-500"></div>
              <div
                class="relative flex-1 flex flex-col-reverse lg:flex-col items-center justify-center lg:max-w-sm"
              >
                <div class="lg:flex-1">
                  <h4
                    class="text-blue-500 font-serif text-5xl leading-7 lg:text-6xl font-medium tracking-wider lg:leading-9 text-center"
                  >
                    +1,000
                  </h4>
                  <p
                    class="text-blue-500 text-xs mt-5 leading-6 lg:text-base uppercase tracking-widest text-balance font-medium text-center"
                  >
                    LESSONS RELEASED <br />SINCE WE STARTED
                  </p>
                </div>
                <div
                  class="flex flex-col items-center justify-center h-10 w-9 mb-8 lg:mb-0 lg:mt-10"
                >
                  <span class="w-4 h-4 rounded-full border border-blue-500"></span>
                  <span class="h-full flex-1 w-px bg-blue-500"></span>
                </div>
              </div>
              <div class="relative flex-1 flex flex-col items-center justify-center lg:max-w-sm">
                <div class="block lg:hidden flex-col items-center justify-center h-10 w-9 mb-6">
                  <span class="h-full flex mx-auto flex-1 w-px bg-blue-500"></span>
                </div>
                <div class="lg:flex-1">
                  <h4
                    class="text-blue-500 font-serif text-5xl leading-7 lg:text-6xl font-medium tracking-wider lg:leading-9 text-center"
                  >
                    +120,000
                  </h4>
                  <p
                    class="text-blue-500 text-xs mt-5 leading-6 lg:text-base uppercase tracking-widest text-balance font-medium text-center"
                  >
                    COMMUNITY OF <br />CREATIVE LEARNERS
                  </p>
                </div>
                <div
                  class="flex flex-col items-center justify-center h-10 w-9 mt-4 mb-3 lg:mb-0 lg:mt-10"
                >
                  <span class="hidden lg:block w-4 h-4 rounded-full border border-blue-500"></span>
                  <span class="h-full flex-1 w-px bg-blue-500"></span>
                </div>
              </div>
              <div class="relative flex-1 flex flex-col items-center justify-center lg:max-w-sm">
                <h4
                  class="text-blue-500 font-serif text-5xl leading-7 lg:text-6xl font-medium tracking-wider lg:leading-9 text-center"
                >
                  +50
                </h4>
                <p
                  class="text-blue-500 text-xs mt-5 leading-6 lg:text-base uppercase tracking-widest text-balance font-medium text-center"
                >
                  YOUNG ADULTS SPONSORED <br />THROUGH THE SPEAR PROGRAMME
                </p>
                <div
                  class="flex flex-col-reverse lg:flex-col items-center justify-center h-10 w-9 mt-10"
                >
                  <span class="w-4 h-4 rounded-full border border-blue-500"></span>
                  <span class="h-full flex-1 w-px bg-blue-500"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <QuoteSlider />

        <div class="bg-white relative">
          <div class="container relative z-10 pt-14 pb-0 md:pb-14 lg:py-28">
            <h4
              class="font-serif mx-auto w-52 sm:w-auto text-blue-800 text-4xl lg:text-5xl font-medium text-center mb-12 lg:mb-24"
            >
              {{ item.values.title }}
            </h4>
            <div class="flex flex-col flex-wrap justify-between md:flex-row gap-x-24">
              <div class="w-full md:w-auto md:flex-1">
                <div class="flex flex-col gap-y-4 md:gap-y-20">
                  <div
                    class="w-72 md:w-auto flex md:hidden flex-col gap-y-2 md:gap-y-5 mb-4 md:mb-0 max-w-md mx-0 md:mx-12"
                  >
                    <h4 class="font-serif font-medium text-3xl md:text-6xl text-yellow-800">
                      Build Creative Confidence.
                    </h4>
                    <p
                      class="text-base font-normal text-black tracking-wide md:tracking-normal md:text-2xl"
                    >
                      We want to empower people, encourage experiments and help others grow.
                    </p>
                  </div>
                  <div>
                    <img
                      src="@/assets/images/backgrounds/values-bg-01.png"
                      alt="Build , Creative"
                      class="h-auto w-3/4 md:w-auto max-w-full object-cover"
                      width="560"
                      height="560"
                    />
                  </div>
                  <div class="hidden md:flex flex-col gap-y-2 lg:gap-y-5 max-w-md mx-6 lg:mx-12">
                    <h4 class="font-serif font-medium text-3xl lg:text-6xl text-green-500">
                      Leave a <br class="hidden xl:block" />Positive Impact.
                    </h4>
                    <p
                      class="text-base font-normal text-black tracking-wide lg:tracking-normal lg:text-2xl"
                    >
                      We are planet-positive, community-minded and purposeful in our actions.
                    </p>
                  </div>
                  <div class="hidden md:block">
                    <img
                      src="@/assets/images/backgrounds/values-bg-03.png"
                      alt="Build , Creative"
                      class="h-auto max-w-full object-cover"
                      width="504"
                      height="518"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full md:w-auto md:flex-1">
                <div class="flex flex-col gap-y-4 mt-8 md:mt-0 md:gap-y-20">
                  <div class="hidden md:flex flex-col gap-y-2 lg:gap-y-5 max-w-md mx-6 lg:mx-12">
                    <h4 class="font-serif font-medium text-3xl lg:text-6xl text-yellow-800">
                      Build Creative Confidence.
                    </h4>
                    <p
                      class="text-base text-black font-normal tracking-wide lg:tracking-normal lg:text-2xl"
                    >
                      We want to empower people, encourage experiments and help others grow.
                    </p>
                  </div>
                  <div
                    class="w-72 ml-auto md:ml-0 md:w-auto flex md:hidden flex-col items-end md:items-start gap-y-2 md:gap-y-5 mb-4 md:mb-0 max-w-md mx-0 md:mx-12"
                  >
                    <h4
                      class="text-right md:text-left font-serif font-medium text-3xl md:text-6xl text-green-500"
                    >
                      Leave a <br class="hidden xl:block" />Positive Impact.
                    </h4>
                    <p
                      class="text-right md:text-left font-normal text-base text-black tracking-wide md:tracking-normal md:text-2xl"
                    >
                      We are planet-positive, community-minded and purposeful in our actions.
                    </p>
                  </div>
                  <div class="flex justify-end md:justify-start">
                    <img
                      src="@/assets/images/backgrounds/values-bg-02.png"
                      alt="Positive Impact"
                      class="h-auto w-4/5 md:w-full max-w-full object-cover"
                      width="560"
                      height="718"
                    />
                  </div>
                  <div
                    class="w-72 md:w-auto flex flex-col gap-y-2 lg:gap-y-5 max-w-md mt-4 mb-4 md:mb-0 md:mt-0 mx-0 md:mx-6 lg:mx-12"
                  >
                    <h4 class="font-serif font-medium text-3xl lg:text-6xl text-red-500">
                      Grant <br class="hidden md:block" />Access.
                    </h4>
                    <p
                      class="text-base font-normal text-black tracking-wide lg:tracking-normal lg:text-2xl"
                    >
                      We believe in unlocking guarded industries to share creative knowledge with as
                      many people as possible.
                    </p>
                  </div>
                  <div class="block md:hidden">
                    <img
                      src="@/assets/images/backgrounds/values-bg-03.png"
                      alt="Grant Access"
                      class="h-auto max-w-full object-cover"
                      width="504"
                      height="518"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="!currentUser">
          <!-- Membership Banner -->
          <div class="relative customBannerHeight">
            <div
              class="bg-neutral-200 customBannerBg block md:hidden lg:bg-transparent absolute left-0 top-0 w-full h-full"
            ></div>
            <SubscribeBanner
              v-if="category.showMembershipBanner"
              :category="category"
              :membershipPlan="membershipPlan"
              :geoProps="geoProps"
              :customImage="true"
              component="Home Promotional Banner"
              :imageUrl="require('@/assets/images/backgrounds/banner/subscribe-banner.png')"
              :respimageUrl="
                require('@/assets/images/backgrounds/banner/subscribe-banner_mobile.png')
              "
              title="Ready to Start Learning?"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
  import { metaTags } from '@/mixins/metaTags'
  import currentUser from '@/mixins/currentUser'
  import brightcove from '@/mixins/brightcove'
  import imgSrcset from '@/mixins/imgSrcset'

  import MetaTags from '@/Components/Shared/MetaTags'
  import SubscribeBanner from '@/Components/Shared/Categories/SubscribeBanner'
  import VideoPlayer from '@/Components/Shared/VideoPlayer'
  import QuoteSlider from '@/Components/Shared/About/QuoteSlider'

  export default {
    mixins: [brightcove, currentUser, metaTags, imgSrcset],

    components: {
      MetaTags,
      SubscribeBanner,
      VideoPlayer,
      QuoteSlider
    },

    props: {
      relatedCategories: {
        type: Array,
        default: () => {
          return []
        }
      },
      about: {
        type: Object,
        required: true
      },
      category: {
        type: Object,
        default: () => ({})
      },
      geoProps: {
        type: Object,
        required: true
      },
      membershipPlan: {
        type: Object,
        default: null
      },
      content: {
        type: Array,
        default: () => [
          {
            about: {
              title: 'About Create Academy',
              description:
                'We’re on a mission to help you build your creative confidence and live a more creatively fulfilled life. Through beautifully curated video courses, we give you unprecedented access to the expertise of today’s best designers, makers, gardeners and chefs - allowing you to unlock your creativity whenever and wherever you want.',
              videoId: '6264273051001'
            },
            story: {
              title: 'Our Story',
              para_one:
                "Create Academy was born out of a passion for creating beautiful films and imagery with a longing to learn from exceptional individuals. As co-founders, we wanted to bring our combined years of production expertise to what we felt was quite a dry and frustrating world of online learning - combining Will's experience in music, Josh's in film and mine in TV and psychology - to create a learning community for creatives, by creatives.",
              para_two:
                'Since forming Create Academy we’ve had the privilege to work with some of the world’s best designers, gardeners, makers and chefs, partnering with them to create courses that distill their expertise into curated video lessons. We hope to immerse people into the lives of these extraordinary creatives and not just inspire but also equip people with the practical tools and skills to take on their homes, gardens and creative projects themselves.',
              para_three:
                'I hope that as you spend time on our platform you grow in confidence and joy as your creativity flourishes.',
              founder: 'Julian Parmiter, CEO & Co-Founder'
            },
            values: {
              title: 'Our Values',
              topics: [
                {
                  title: 'Build Creative Confidence.',
                  description:
                    'We want to empower people, encourage experiments and help others grow.'
                },
                {
                  title: 'Leave a Positive Impact.',
                  description:
                    'We are planet-positive, community-minded and purposeful in our actions.'
                },
                {
                  title: 'Grant Access.',
                  description:
                    'We believe unlocking guarded industries to share creative knowledge with as many people as possible.'
                }
              ]
            }
          }
        ]
      }
    },

    computed: {
      currentPage() {
        return 'About Us'
      },

      gtmSectionExploreCategories() {
        return 'Explore Categories'
      },

      bannerImage() {
        if (this.AboutUsBanner) {
          return this.imgSrcsetBanner(this.AboutUsBanner)
        } else {
          return { url: null, srcset: [] }
        }
      },

      founderImage() {
        if (this.FounderBanner) {
          return this.imgSrcsetBanner(this.FounderBanner)
        } else {
          return { url: null, srcset: [] }
        }
      }
    },

    created() {
      this.AboutUsBanner = {
        url: require('@/assets/images/backgrounds/about-us-background-large.jpg'),
        sizes: {
          large: require('@/assets/images/backgrounds/about-us-background-large.jpg'),
          medium: require('@/assets/images/backgrounds/about-us-background-small.jpg'),
          small: require('@/assets/images/backgrounds/about-us-background-small.jpg')
        }
      }

      this.FounderBanner = {
        url: require('@/assets/images/backgrounds/founder-image.webp'),
        sizes: {
          large: require('@/assets/images/backgrounds/founder-image.webp'),
          medium: require('@/assets/images/backgrounds/founder-image-small.webp'),
          small: require('@/assets/images/backgrounds/founder-image-small.webp')
        }
      }

      this.i18nScope = { scope: 'pages.static' }
    }
  }
</script>
