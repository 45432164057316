<template>
  <div class="bg-red-100 lg:bg-transparent memberSlider">
    <div class="container pt-8 pb-12 lg:pt-0">
      <div class="flex flex-col mb-2 lg:mb-0 gap-1 lg:gap-3">
        <h2 class="text-3xl lg:text-4xl font-serif font-medium text-black text-center capitalize">
          Hear from our Members
        </h2>
        <p class="text-center text-base lg:text-xl text-black font-normal">
          Click play to hear from our current Create Academy Members
        </p>
      </div>
      <div class="testimonial-grid mt-8 lg:mt-14 relative">
        <div class="video-wrapper">
          <div class="video-item" v-for="(testimonial, index) in testimonials" :key="index">
            <div class="bg-neutral-200 overflow-hidden video-container">
              <div class="relative w-full h-0" style="padding-top: 177.78%">
                <VerticalVideo
                  :video-id="testimonial.videoId"
                  :account-id="accountId"
                  :player-id="playerId"
                  :unique-id="`video-${index}`"
                  class="absolute inset-0"
                  @video-play="trackVideoPlay(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed } from 'vue'
  import VerticalVideo from './VerticalVideo.vue'
  import mixpanelMixin from '@/mixins/mixpanel'

  export default defineComponent({
    name: 'MemberSlider',
    components: {
      VerticalVideo
    },
    mixins: [mixpanelMixin],
    data() {
      return {
        accountId: '6222962662001',
        playerId: 'awlLVI5lZ'
      }
    },
    computed: {
      testimonials() {
        return [
          { videoId: '6363075819112' },
          { videoId: '6363075133112' },
          { videoId: '6363075510112' },
          { videoId: '6363076111112' }
        ]
      }
    },
    methods: {
      trackVideoPlay(index) {
        const videoId = this.testimonials[index].videoId
        this.trackEvent('UGC Video Played', {
          videoId: videoId,
          index: index
        })
      }
    }
  })
</script>

<style scoped>
  .testimonial-grid {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .video-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .video-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .video-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  @media (min-width: 640px) {
    .video-item {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }

  @media (min-width: 768px) {
    .video-item {
      flex: 0 0 calc(33.33% - 13.33px);
      max-width: calc(33.33% - 13.33px);
    }
  }

  @media (min-width: 1024px) {
    .video-item {
      flex: 0 0 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }

    .video-container {
      max-width: 100%;
    }
  }
</style>
