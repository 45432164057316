<template>
  <div>
    <MetaTags :metaTags="metaTags" />
    <!-- Feature Banner -->
    <Slider v-if="!isSubscribed" :slides="slidesWithCommonTitle" sliderId="althome-banner-slider" />

    <template v-if="!currentUser">
      <!-- Press References -->
      <div class="mt-8 lg:mt-10">
        <h4 class="text-sm lg:text-base font-normal text-center lg:font-medium uppercase mb-4">
          As Seen In
        </h4>
      </div>
      <PressReferences :pressReferences="pressReferences" sliderId="home-press-references-slider" />
    </template>

    <!-- Continue Watching Section -->
    <ContinueWatchingSection v-if="currentUser" />

    <template v-if="!isSubscribed && !currentUser">
      <div class="bg-neutral-100">
        <div class="container py-12 lg:py-24">
          <!-- Course Slider Section -->
          <CourseSliderSection
            v-if="!currentUser"
            :categories="categories"
            :brightcove="brightcove"
            :gtmPageName="currentPage"
            :membershipPlan="membershipPlan"
          />
        </div>
      </div>
    </template>

    <!-- <FeaturedCollectionSection :brightcove="brightcove.lessonPlayer" :gtmPageName="currentPage" /> -->

    <template>
      <AboutSection />
    </template>

    <!-- User reviews portrait videos -->
    <template>
      <MemberSlider />
    </template>

    <template v-if="!isSubscribed">
      <!-- Membership Banner -->
      <SubscribeBanner
        v-if="category.showMembershipBanner"
        :category="category"
        :membershipPlan="membershipPlan"
        :geoProps="geoProps"
        :customImage="true"
        component="Home Promotional Banner"
        :imageUrl="require('@/assets/images/backgrounds/banner/subscribe-banner.png')"
        :respimageUrl="require('@/assets/images/backgrounds/banner/subscribe-banner_mobile.png')"
        title="Access all Courses"
        :subText="true"
      />
    </template>

    <div class="bg-white lg:bg-neutral-100">
      <FourSquares :squares="squaresData" />
    </div>

    <!-- Trustpilot -->
    <template>
      <ReviewSlider />
    </template>

    <div v-if="!isSubscribed" class="container p-0">
      <!-- Seasonal Banner -->
      <SeasonalBanner
        v-if="seasonalBanner.active && !currentUser"
        :seasonalBanner="seasonalBanner"
      />
    </div>

    <!-- Gift Banner -->
    <!-- <GiftingBanner v-if="!currentUser" /> -->

    <div v-if="!currentUser" class="container py-12 lg:py-24">
      <!-- Categories Section -->
      <!-- <CategorySection v-if="!currentUser" :categories="categories" :gtmPageName="currentPage" /> -->

      <!-- Upcoming Courses Section -->
      <!-- <UpcomingCourseSection :gtmPageName="currentPage" /> -->

      <!-- <template v-if="!currentUser">
        <Testimonials />
        <Divider spacing="xl" />
      </template> -->

      <template v-if="!currentUser">
        <div class="flex flex-col mb-5 lg:mb-12">
          <h2 class="text-3xl lg:text-4xl font-serif font-medium text-black text-center capitalize">
            Frequently asked questions
          </h2>
        </div>

        <Faq :gtmPageName="currentPage" class="w-full mx-auto" />

        <!-- <ButtonLink
          data-testid="explore-best-sellers-button"
          href="/best-selling-courses"
          class="mt-8 mx-auto"
          variant="yellow"
        >
          Bestsellers
        </ButtonLink> -->
      </template>
    </div>
    <FreeLessonBanner
      v-if="!currentUser"
      :category="category"
      :membershipPlan="membershipPlan"
      :geoProps="geoProps"
      :customImage="true"
      component="Home Promotional Banner"
      :imageUrl="require('@/assets/images/backgrounds/banner/freelesson_banner.png')"
      :respimageUrl="require('@/assets/images/backgrounds/banner/freelesson_banner_mobile.png')"
    />
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import currentUser from '@/mixins/currentUser'
  import statuses from '@/mixins/statuses'
  import subscriptionConfirmationModal from '@/mixins/subscriptionConfirmationModal'
  import mixpanelMixin from '@/mixins/mixpanel'
  import Faq from '@/Components/Shared/Faq/Index'
  import MetaTags from '@/Components/Shared/MetaTags'
  import PressReferences from '@/Components/Shared/PressReferences/Index'
  import SeasonalBanner from '@/Components/Shared/SeasonalBanner'
  import Slider from '@/Components/Shared/AltSlider'
  import SubscribeBanner from '@/Components/Shared/Categories/SubscribeBanner'
  import FreeLessonBanner from '@/Components/Shared/Categories/FreeLessonBanner'
  import AboutSection from '@/Components/Shared/Dashboard/AboutSection'
  import MemberSlider from '@/Components/Shared/Dashboard/MemberSlider'
  import ReviewSlider from '@/Components/Shared/Dashboard/ReviewSlider'
  import FourSquares from '@/Components/Category/LandingTest/FourSquares.vue'

  export default {
    mixins: [metaTags, currentUser, statuses, subscriptionConfirmationModal, mixpanelMixin],

    components: {
      Faq,
      MetaTags,
      PressReferences,
      SeasonalBanner,
      Slider,
      SubscribeBanner,
      AboutSection,
      MemberSlider,
      FourSquares,
      ReviewSlider,
      FreeLessonBanner,
      ContinueWatchingSection: () =>
        import('@/Components/Shared/Dashboard/ContinueWatchingSection'),
      CourseSliderSection: () => import('@/Components/Shared/Dashboard/AltCourseSliderSection')
    },

    props: {
      brightcove: {
        type: Object,
        required: true
      },
      categories: {
        type: Array,
        default: () => []
      },
      category: {
        type: Object,
        default: () => ({})
      },
      about: {
        type: Object
      },
      pressReferences: {
        type: Array,
        default: () => []
      },
      seasonalBanner: {
        type: Object,
        default: () => ({})
      },
      collections: {
        type: Array,
        default: () => []
      },
      geoProps: {
        type: Object,
        required: true
      },
      membershipPlan: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        slides: [
          {
            image_slide_paths: require('@/assets/images/backgrounds/banner/banner-slider01.png'),
            subtitle:
              'Find your creative spark with Create Academy. Learn from world-renowned instructors across thousands of lessons - all at your fingertips.',
            cta_link: '/get-started',
            cta_copy: 'Start Learning'
          },
          {
            image_slide_paths: require('@/assets/images/backgrounds/banner/banner-slider02.png'),
            subtitle:
              'Find your creative spark with Create Academy. Learn from world-renowned instructors across thousands of lessons - all at your fingertips.',
            cta_link: '/get-started',
            cta_copy: 'Start Learning'
          },
          {
            image_slide_paths: require('@/assets/images/backgrounds/banner/banner-slider03.png'),
            subtitle:
              'Find your creative spark with Create Academy. Learn from world-renowned instructors across thousands of lessons - all at your fingertips.',
            cta_link: '/get-started',
            cta_copy: 'Start Learning'
          },
          {
            image_slide_paths: require('@/assets/images/backgrounds/banner/banner-slider04.png'),
            subtitle:
              'Find your creative spark with Create Academy. Learn from world-renowned instructors across thousands of lessons - all at your fingertips.',
            cta_link: '/get-started',
            cta_copy: 'Start Learning'
          },
          {
            image_slide_paths: require('@/assets/images/backgrounds/banner/banner-slider05.png'),
            subtitle:
              'Find your creative spark with Create Academy. Learn from world-renowned instructors across thousands of lessons - all at your fingertips.',
            cta_link: '/get-started',
            cta_copy: 'Start Learning'
          }
        ],
        squaresData: [
          {
            imageSrc: '/images/interior-landing/1.jpg',
            altText: 'Description of image 1',
            title: 'Gardening & Floristry',
            description: 'Description for square 1',
            link: this.$routes.category('online-gardening-and-floristry-courses')
          },
          {
            imageSrc: '/images/interior-landing/2.jpg',
            altText: 'Description of image 2',
            title: 'Decorative arts & craft',
            description: 'Description for square 2',
            link: this.$routes.category('online-arts-and-craft-courses')
          },
          {
            imageSrc: '/images/interior-landing/3.jpg',
            altText: 'Description of image 4',
            title: 'Food & Drink',
            description: 'Description for square 4',
            link: this.$routes.category('online-food-and-cooking-courses')
          },
          {
            imageSrc: '/images/interior-landing/4.jpg',
            altText: 'Description of image 3',
            title: 'Bestsellers',
            description: 'Bestsellers',
            link: this.$routes.category('best-selling-courses')
          }
        ]
      }
    },

    computed: {
      currentPage() {
        return 'Home'
      },
      commonSlideTitle() {
        const endDate = new Date('2024-11-10T23:59:59')
        const currentDate = new Date()

        if (currentDate <= endDate) {
          return 'Celebrate Our Birthday with 20% Off All Courses'
        } else {
          return 'Bring Your Creative Dreams to Life'
        }
      },
      slidesWithCommonTitle() {
        return this.slides.map(slide => ({
          ...slide,
          title: this.commonSlideTitle
        }))
      }
    },

    methods: {
      visibilityChanged(isVisible, entry) {
        const key = entry.target.getAttribute('aria-label')
        this.visibility[key] = isVisible
      },
      trackAltHomeView() {
        this.trackEvent('Home Viewed: Conversionwise', {
          isSubscribed: this.isSubscribed,
          hasCurrentUser: !!this.currentUser
        })
      }
    },
    mounted() {
      sessionStorage.clear()
      this.trackAltHomeView()
    },
    created() {
      this.i18nScope = { scope: 'pages.home.section_titles' }
    }
  }
</script>
