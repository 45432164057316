<template>
  <div
    data-testid="gift-preview-modal"
    class="h-full bg-white flex flex-col justify-start"
  >
    <!-- <div class="z-10 absolute top-4 right-4">
      <button
        data-testid="add-campaign-to-cart-modal-close"
        @click="closeModal"
      >
        <Icon name="close" class="text-grayscale-100 text-2xl" />
      </button>
    </div> -->

    <!-- <div class="flex flex-col items-center w-full bg-white py-7">
      <Logotype class="h-9 w-auto text-blue-800" />
    </div> -->
    <div class="w-full h-56">
      <template v-if="isMembershipGift">
        <img loading="lazy"
          class="w-full image-cover"
          src="@/assets/images/membership-gift-voucher.webp"
        />
      </template>
      <template v-else>
        <img loading="lazy"
          class="w-full image-cover"
          src="@/assets/images/gift-voucher.webp"
        />
      </template>
    </div>
    <div class="bg-grayscale-100 px-10 pt-8 pb-12">
      <p
        class="font-serif italic text-3xl text-center font-medium mb-8"
        :class="[isMembershipGift ? 'text-blue-500' : 'text-red-800']"
      >
        You’ve been gifted
      </p>
      <div class="flex flex-col items-center text-center px-8">
        <div
          class="w-full rounded-3xl px-8 py-6 flex flex-col justify-center items-center text-white mb-8 relative"
          :class="[isMembershipGift ? 'bg-blue-500' : 'bg-red-500']"
        >
          <div class="absolute left-0 top-12">
            <img loading="lazy"
              class="w-full image-cover"
              src="@/assets/images/voucher-pattern.svg"
            />
          </div>
          <div class="absolute right-0 top-16">
            <img loading="lazy"
              class="w-full image-cover"
              src="@/assets/images/voucher-pattern-right.svg"
            />
          </div>
          <Logotype class="h-7 w-auto text-white text-center" />
          <Divider label="Joyful Learning" class="voucher_divider_custom" />
          <p class="font-medium font-serif text-white leading-normal w-11/12">
            <span v-if="isMembershipGift" class="text-5xl">
              1-Year <br />
              Membership
            </span>
            <span v-else class="text-7xl">
              £{{ campaign.unformattedPrice }}
            </span>
          </p>
          <p
            v-if="!isMembershipGift"
            class="text-sm uppercase text-grayscale-100 tracking-wider font-medium"
          >
            {{ $I18n.t(`${previewScope}.gift_voucher`, i18nScope) }}
          </p>
          <p
            class="text-base text-white uppercase font-medium tracking-wider mt-5"
          >
            {{
              `${$I18n.t(
                `${previewScope}.code_label`,
                i18nScope
              )}${voucherCode}`
            }}
          </p>
        </div>
        <p
          v-if="message"
          data-testid="gift-preview-message"
          class="font-normal italic text-xl break-words mb-8"
          :class="[isMembershipGift ? 'text-blue-500' : 'text-red-500']"
        >
          {{ message }}
        </p>
        <p
          class="w-full text-center font-normal text-base text-blue-800 leading-5"
        >
          {{ $I18n.t(`${previewScope}.instructions`, i18nScope) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Icon from '@/Components/Shared/Icon'
import Logotype from '@/Components/Shared/Logotype'
import Divider from '@/Components/Shared/Divider'

export default {
  components: {
    // Icon,
    Logotype,
    Divider
  },

  props: {
    campaign: {
      type: Object,
      required: true
    },
    message: {
      type: String
    },
    code: {
      type: String
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    }
  },

  computed: {
    voucherCode() {
      return this.code
        ? this.code
        : this.$I18n.t(`${this.previewScope}.code_example`, this.i18nScope)
    },
    isMembershipGift() {
      return this.campaign.category === 'membership_gift'
    },
    previewScope() {
      return this.isMembershipGift ? 'membership_gift_preview' : 'gift_preview'
    }
  }
}
</script>
