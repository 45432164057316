<template>
  <div :id="sliderId" class="swiper-banner swiper-container" ref="mySwiper">
    <div class="swiper-wrapper">

      <div class="swiper-slide" v-for="(slide, index) in slides" :key="index" :data-swiper-autoplay="slideAutoPlayDuration(slide)">
        <Banner
          :uid="index"
          :image-src="slide.image_slide_paths"
          :image-alt="slide.title"
          :video-id="slide.video && slide.video.host_video_id"
          :video-auto-play="index === 0"
          :lazy-slide="index !== 0"
          :brightcove="brightcove"
        >

          <!-- Slide title and subtitle -->
          <h1 data-testid="slide-title" class="banner__title">{{ slide.title }}</h1>
          <p data-testid="slide-subtitle" class="banner__subtitle">{{ subtitleContent(slide) }}</p>

          <!-- Slide CTA -->
          <ButtonLink
            v-if="showButtonLink(slide)"
            data-testid="banner-link"
            :href="buttonLinkUrl(slide)"
            @click="trackMixpanelEvent"
            class="mt-8 sm:mt-10"
            inverse>
            {{ buttonLinkContent(slide) }}
          </ButtonLink>

          <p
            v-else-if="isSignedInWithCtaCopy(slide)"
            data-testid="banner-link"
            class="mt-4 w-max p-2 px-4 bg-announcement rounded-sm text-grayscale-100 tracking-wide text-base font-medium uppercase">
            {{ slide.signed_in_cta_copy }}
          </p>
        </Banner>
      </div>

    </div>
    <template v-if="slides.length > 1">
      <!-- Slide pagination -->
      <div  data-testid="slide-pagination" class="swiper-pagination"></div>

      <!-- Slide navigation -->
      <div data-testid="slide-button-prev" class="swiper-button-prev"></div>
      <div data-testid="slide-button-next" class="swiper-button-next"></div>
    </template>
  </div>
</template>

<script>
import Swiper from 'swiper'

import Banner from '@/Components/Shared/Banner'
import ButtonLink from '@/Components/Shared/ButtonLink'

import currentUser from '@/mixins/currentUser'
import mixpanel from '@/mixins/mixpanel'

// Configure Swiper to use modules
import SwiperCore, { Navigation, Pagination, Lazy, Autoplay } from 'swiper/core'

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

export default {
  mixins: [
    currentUser,
    mixpanel
  ],

  components: {
    Banner,
    ButtonLink
  },

  props: {
    brightcove: {
      type: Object,
      required: true
    },
    slides: Array,
    sliderId: {
      type: String,
      required: true
    }
  },

  methods: {
    // check if the user is signed in and if they have a cta link to click on
    isSignedInWithCtaLink(slide) {
      return this.currentUser && slide.signed_in_cta_link
    },

    // check if the user is signed out and if they have a cta link to click on
    isSignedOutWithCtaLink(slide) {
      return !this.currentUser && slide.cta_link
    },

    // check if the ButtonLink is going to be shown
    showButtonLink(slide) {
      return this.isSignedInWithCtaLink(slide) || this.isSignedOutWithCtaLink(slide)
    },

    // returns the content for the subtitle
    subtitleContent(slide) {
      return this.currentUser ? slide.signed_in_subtitle : slide.subtitle
    },

    // returns the url for the button
    buttonLinkUrl(slide) {
      return this.currentUser ? slide.signed_in_cta_link : slide.cta_link
    },

    // returns the text that will be showed in the banner button
    buttonLinkContent(slide) {
      if (this.currentUser) {
        return slide.signed_in_cta_copy || slide.signed_in_cta_link
      } else {
        return slide.cta_copy || slide.cta_link
      }
    },

    // check if there is only text to be shown in the banner (instead of a button)
    isSignedInWithCtaCopy(slide) {
      return this.currentUser && slide.signed_in_cta_copy
    },

    slideAutoPlayDuration(slide) {
      return slide.video ? slide.video.duration : null
    },

    async onActiveIndex(swiper) {
      let videoToPlay = null

      const slideVideoEl = document.getElementById(`banner-player-${swiper.activeIndex}`)
      if (slideVideoEl) {
        videoToPlay = slideVideoEl.getElementsByTagName('video')[0]
      }

      if (this.playPromise) {
        // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        await this.playPromise // wait for promise before pause
      }

      // pause previous video if exists
      this.activeVideo?.pause()
      // play current video if exists
      this.playPromise = videoToPlay?.play()?.catch(() => {
        // error on play()
      })
      this.activeVideo = videoToPlay
    },

    trackMixpanelEvent() {
      // Dynamic URL from featured section,
      // currently its mapped to Onboarding Flow
      // hence subscribe_button event is tagged
      // must be updated to include other cases
      this.trackSubscribeButton()
    },

    trackSubscribeButton() {
      this.trackEvent('subscribe_button', {
        userType: this.currentUser ? 'authed' : 'anonymous',
        page: window.location.pathname,
        component: 'Home Hero Section'
      })
    }
  },

  mounted() {
    // init Swiper
    this.swiper = new Swiper(`#${this.sliderId}`, {
      centeredSlides: true,
      allowTouchMove: this.slides.length > 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      // Lazy loading - requires proper cache policy
      lazy: {
        loadPrevNext: false,
        loadOnTransitionStart: true
      },
      // Disable preloading of all images
      preloadImages: false,
      // If we need pagination
      pagination: this.slides.length > 1 ? {
        el: '.swiper-pagination'
      }:false,
      // Navigation arrows
      navigation: this.slides.length > 1 ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }:false,
      on: {
        transitionStart: this.onActiveIndex
      }
    })
  },

  created() {
    this.activeVideo = null
    this.playPromise = null
  }
}
</script>
