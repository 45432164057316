<template>
  <div class="w-full">
    <MetaTags :metaTags="metaTags" />
    <!-- banner -->
    <div class="w-full" style="background: var(--blue-300, #e8ebf0)">
      <div class="container mx-auto px-0 lg:px-40 pt-8 pb-0 lg:pt-16 lg:pb-24">
        <div class="flex flex-col justify-start items-center">
          <h2
            class="font-serif text-black px-4 lg:px-0 text-4xl lg:text-6xl font-medium mb-2 lg:mb-4 text-center"
          >
            Bunny Williams
          </h2>

          <p
            class="uppercase text-black font-normal px-4 lg:px-0 text-lg tracking-wide lg:text-3xl lg:tracking-widest mb-4 lg:mb-7 text-center"
          >
            HOW TO DESIGN YOUR DREAM HOME
          </p>
          <p
            class="text-black text-lg lg:leading-9 px-4 lg:px-0 lg:text-2xl lg:tracking-wide mb-2 lg:mb-5 text-center max-w-screen-lg mx-auto"
          >
            In her very first online course, the inimitable Bunny Williams opens the doors of her
            country home in Connecticut and her Manhatten apartment, offering a deep dive into the
            design philosophy, practicality and inspiration born from more than 50 years experience.
          </p>
          <p
            class="text-yellow-800 text-lg lg:text-2xl px-4 lg:px-0 tracking-[0.36px] lg:tracking-[0.48px] mb-0 text-center font-brandon font-normal leading-[160%]"
          >
            Released 19th November
          </p>
          <div class="relative mt-8 lg:mt-11 w-full">
            <!-- <img
                :data-src="this.featureBlockOne.url"
                :srcset="this.featureBlockOne.srcset"
                alt="rita_banner_image_alt"
                class="image-cover lazyload"
              /> -->
            <!-- Video Player -->
            <section class="w-full">
              <CustomVideoPlayer
                :isAutoPlay="false"
                :options="{
                  accountId: 6222962662001,
                  videoId: 6363742566112,
                  playerId: '3BqAJwkg7',
                  embedId: 'default'
                }"
                :mixpanel="{ type: 'landing', videoId: 6363742566112 }"
                :thumbnailUrl="featureBlockOne.url"
                :thumbnailSrcset="featureBlockOne.sizes"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- 1-2-1 -->
    <div class="w-full" style="background: var(--neutral-100, #f7f3ed)">
      <div class="container mx-auto px-4 lg:px-40 lg:py-12">
        <div class="flex flex-col">
          <h4
            class="text-blue-800 text-4xl lg:text-6xl font-medium font-serif mb-5 lg:mb-8 text-center"
          >
            Win $1,000 to spend at Bunny Williams Home
          </h4>
          <p
            class="text-yellow-800 text-center font-['Brandon_Grotesque'] text-[18px] font-medium leading-[140%] tracking-[2.7px] uppercase lg:tracking-widest lg:leading-9 lg:text-2xl mb-5 mx-auto max-w-xs px-6 md:px-0 md:max-w-full"
          >
            Subscribe by 18th NOVEMBER AND YOU'LL HAVE
          </p>
          <ul class="max-w-max mx-auto space-y-6 lg:space-y-4 mb-7">
            <li v-for="item in includesData" :key="item.id" class="flex">
              <Icon name="Check" class="lg:text-base fill_none iconBlue mr-4 relative lg:top-1" />
              <span
                class="text-left font-normal text-grayscale-700 flex-1 text-lg leading-6 lg:text-xl"
                >{{ item.text }}</span
              >
            </li>
          </ul>
          <div class="flex flex-col items-center gap-5">
            <p
              class="text-blue-800 lg:text-grayscale-900 font-medium lg:text-2xl lg:tracking-widest text-center text-lg tracking-wide"
            >
              <Timer targetDateTime="2024-11-18 23:59:59" />
            </p>
            <div class="flex justify-center w-full">
              <div class="w-full" style="max-width: 600px">
                <SubscribeButton
                  :membershipPlan="membershipPlan"
                  :buttonBorder="false"
                  component="Bunny-williams-courses CTA"
                  class="flex justify-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- course outline -->
    <div class="w-full">
      <div class="container px-4 lg:px-40 lg:pt-24 lg:pb-10">
        <div class="w-full flex flex-col gap-8 lg:gap-14">
          <h4
            class="text-3xl leading-9 lg:text-5xl font-medium font-serif text-blue-800 text-center"
          >
            Course Outline
          </h4>
          <div class="flex items-center justify-between lg:gap-14">
            <div class="flex flex-col flex-1 gap-8 items-center lg:items-start">
              <div
                v-for="item in courseOutline"
                :key="item.id"
                class="w-full flex flex-col gap-2 lg:gap-3 text-center lg:text-left pb-8 last:border-b-0 last:pb-0 border-b border-blue-300 max-w-lg"
              >
                <p
                  class="uppercase text-yellow-800 text-xl lg:text-2xl font-medium leading-9 lg:leading-9 tracking-widest"
                >
                  {{ item.main }}
                </p>
                <p
                  class="text-grayscale-700 text-lg lg:text-2xl leading-9 lg:leading-9 font-normal"
                >
                  {{ item.text }}
                </p>
              </div>
            </div>
            <div class="w-full flex-1 hidden lg:flex justify-end lg:max-w-xl minWidth-img">
              <img
                loading="lazy"
                :data-src="this.featureBlockTwo.url"
                :srcset="this.featureBlockTwo.srcset"
                alt="course_outline_image_alt"
                class="image-cover lazyload"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- subscribe -->
    <div class="w-full relative bg-yellow-100 lg:bg-white">
      <div class="container px-4 lg:px-32">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-center lg:gap-8 gap-6">
          <div class="flex flex-col pa-5 lg:gap-6 flex-1">
            <img
              loading="lazy"
              :data-src="this.createStar"
              alt="CA"
              class="mx-auto lazyload mb-6 hidden md:block"
            />
            <p
              class="text-3xl leading-9 lg:text-4xl font-medium font-serif text-blue-800 text-center"
            >
              3 design <br />projects
            </p>
          </div>
          <div class="flex flex-col pa-5 lg:gap-6 flex-1">
            <img
              loading="lazy"
              :data-src="this.createStar"
              alt="CA"
              class="mx-auto lazyload mb-6"
            />
            <p
              class="text-3xl leading-9 lg:text-4xl font-medium font-serif text-blue-800 text-center"
            >
              5 hours <br />
              of lessons
            </p>
          </div>
          <div class="flex flex-col pa-5 lg:gap-6 flex-1">
            <img
              loading="lazy"
              :data-src="this.createStar"
              alt="CA"
              class="mx-auto lazyload mb-6"
            />
            <p
              class="text-3xl leading-9 lg:text-4xl font-medium font-serif text-blue-800 text-center"
            >
              Bunny's personal<br />sourcing list
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full relative bg-blue-300">
      <div class="container px-4 lg:px-20 lg:py-20">
        <div class="grid grid-cols-2 gap-4 lg:gap-8 items-center">
          <div class="col-span-2 lg:col-span-1 order-2 lg:order-1">
            <img
              loading="lazy"
              :data-src="this.staticImage.url"
              :srcset="this.staticImage.srcset"
              alt="rita_image_alt"
              class="image-cover lazyload"
            />
            <div class="text-sm text-gray-600 mb-4">Photo by Reid Rolls</div>
          </div>

          <div class="col-span-2 lg:col-span-1 order-1 lg:order-2 lg:pl-10">
            <h4
              class="text-center lg:text-left text-3xl leading-9 mb-2 lg:mb-5 lg:text-5xl font-medium font-serif text-blue-800"
            >
              Win $1,000 to spend at
              <img
                loading="lazy"
                :data-src="require('@/assets/images/landing/bunny/bw-logo.png')"
                alt="Bunny Williams Logo"
                class="lazyload h-14 lg:h-24 w-auto mx-auto lg:mx-0"
              />
            </h4>
            <!-- <h4 class="text-center lg:text-left text-3xl leading-9 mb-2 lg:mb-5 lg:text-5xl font-medium font-serif text-blue-800">20% off annual subscription <span class="inline-block lg:block"><span class="italic">with</span> Rita</span></h4> -->
            <p
              class="text-center lg:text-left lg:text-2xl lg:leading-9 text-grayscale-700 font-normal text-lg leading-8 tracking-wide lg:tracking-normal"
            >
              Discover reproductions of Bunny's treasured items and original designs in the
              <a
                href="https://www.bunnywilliamshome.com"
                target="_blank"
                rel="noopener noreferrer"
                class="font-brandon text-[#238EC4] lg:text-2xl lg:leading-[35px] text-lg leading-[170%] underline"
                style="
                  --system-info: #238ec4;
                  color: var(--system-info);
                  font-family: 'Brandon Grotesque';
                  font-weight: 390;
                  font-style: normal;
                  letter-spacing: 0.18px;
                  text-decoration-line: underline;
                  text-decoration-style: solid;
                  text-decoration-skip-ink: none;
                  text-decoration-thickness: auto;
                  text-underline-offset: auto;
                  text-underline-position: from-font;
                "
                >Bunny Williams Home collection</a
              >. To be in with a chance of winning a $1,000 voucher, subscribe before 18th November.
            </p>

            <div class="mt-8">
              <SubscribeButton
                ref="subscribeButton"
                :membershipPlan="membershipPlan"
                :buttonBorder="true"
                component="Course & Trailer CTA"
              />
              (Billed annually)
            </div>

            <div
              class="hidden lg:flex mt-8 lg:mt-10 flex-col gap-2 lg:gap-3 items-start justify-start"
            >
              <!-- <p class="text-blue-800 text-xs lg:text-base text-left font-normal">(Billed annually) </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonial -->
    <div class="w-full bg-blue-500">
      <div class="container px-4 lg:px-40 lg:py-20">
        <div class="flex flex-col items-start gap-6 lg:gap-4">
          <div
            class="font-serif text-8xl text-grayscale-100 transform rotate-180 text-right tracking-tighter mb-0"
          >
            ,,
          </div>
          <div
            class="-mt-8 lg:-mt-12 text-grayscale-100 text-3xl leading-10 customLeading lg:text-4xl font-medium font-serif"
          >
            “Wonderful content and variety. I signed up for the 1 year subscription a few days ago
            and I have already been inspired in so many ways.
          </div>
          <img
            loading="lazy"
            :data-src="this.ratingStar"
            alt="rita_banner_image_alt"
            class="lazyload w-auto max-w-min"
          />
        </div>
      </div>
    </div>

    <!-- video section -->
    <div class="w-full bg-yellow-100 relative">
      <div class="container px-4 lg:px-40 lg:py-24">
        <div class="flex flex-col gap-4 lg:gap-1.5">
          <p class="text-center lg:text-2xl font-normal grayscale-700 text-lg tracking-wide">
            Competition open to all customers who subscribe before midnight 18th November GMT. The
            winner will be chosen at random and contacted by email. This prize is non-transferable,
            and Bunny Williams Home only ships within the United States.
          </p>
        </div>
      </div>
    </div>

    <!-- terms -->
    <!-- <div class="w-full relative bg-yellow-100">
        <div class="container px-4 lg:px-40 lg:py-20">
          <p class="text-center max-w-screen-xl lg:leading-10  mx-auto lg:text-2xl font-medium text-grayscale-700 text-lg leading-8">Terms & conditions</p>
          <p class="text-center max-w-screen-xl lg:leading-10  mx-auto lg:text-2xl font-normal text-grayscale-700 text-lg leading-8">Free access to the talk with Rita will be granted to all customers who have an active subscription before midnight 16th April BST. The talk will take place in May via zoom and access details will be shared via email ahead of the event. Data and time of the talk is subject to change.</p>
        </div>
      </div> -->
  </div>
</template>

<script>
  // AB Testing bits
  import abTest from '@/mixins/abTest'
  import gtm from '@/mixins/gtm'
  import Icon from '@/Components/Shared/Icon'
  import { metaTags } from '@/mixins/metaTags'
  import MetaTags from '@/Components/Shared/MetaTags'
  import currentUser from '@/mixins/currentUser'
  import CustomVideoPlayer from '@/Components/Shared/CustomVideoPlayer'
  import Timer from '@/Components/Shared/Timer'
  import mixpanel from '@/mixins/mixpanel'
  import SubscribeButton from '@/Components/Shared/Courses/SubscribeButton'
  import LandingAccessAllCourseImage from '@/assets/images/landing/membershipbanner.webp'

  export default {
    mixins: [metaTags, currentUser, mixpanel, abTest, gtm],
    components: {
      Icon,
      MetaTags,
      CustomVideoPlayer,
      Timer,
      SubscribeButton
    },
    props: {
      course: {
        type: Object,
        required: true
      },
      membershipPlan: {
        type: Object,
        default: null
      },
      category: {
        type: Object,
        required: true
      },
      geoProps: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        imageUrl: LandingAccessAllCourseImage
      }
    },
    computed: {
      currentPage() {
        return 'Bunny Williams Landing Page'
      },
      includesData() {
        return [
          {
            id: 1,
            text: 'The chance to win a $1,000 voucher to spend at Bunny Williams Home'
          },
          {
            id: 2,
            text: 'Access to Bunny’s course, when released on 19th November'
          },
          {
            id: 3,
            text: 'Instant access to over 1,000 lessons and downloadable resources'
          },
          {
            id: 4,
            text: 'New content added each month to help develop your skills'
          }
        ]
      },
      courseOutline() {
        return [
          {
            id: 1,
            main: 'Bunny’s Country Home',
            text: 'Explore Bunny’s home in Connecticut to see her unique approach to interior design come to life'
          },
          {
            id: 2,
            main: 'The Design Process',
            text: 'From Bunny’s office on the Upper East Side, learn Bunny’s entire design process from planning to execution'
          },
          {
            id: 3,
            main: 'Bunny’s New York Apartment',
            text: 'Understand how Bunny approached a different type of project whilst maintaining her distinct style'
          },
          {
            id: 4,
            main: 'Working with clients',
            text: 'Gain tips for working with clients during a tour of a project in New York and a visit to a showroom '
          }
        ]
      }
    },
    created() {
      this.i18nScope = { scope: 'pages.static_course' }
      this.featureBlockOne = {
        url: require('@/assets/images/landing/bunny/bunny-image-video.jpeg'),
        sizes: {
          large: require('@/assets/images/landing/bunny/bunny-image-video.jpeg'),
          medium: require('@/assets/images/landing/bunny/bunny-image-video.jpeg'),
          small: require('@/assets/images/landing/bunny/bunny-image-video.jpeg')
        }
      }

      this.featureBlockTwo = {
        url: require('@/assets/images/landing/bunny/bunny-bedroom.jpeg'),
        sizes: {
          large: require('@/assets/images/landing/bunny/bunny-bedroom.jpeg'),
          medium: require('@/assets/images/landing/bunny/bunny-bedroom.jpeg'),
          small: require('@/assets/images/landing/bunny/bunny-bedroom.jpeg')
        }
      }

      this.staticImage = {
        url: require('@/assets/images/landing/bunny/bunny2.webp'),
        sizes: {
          large: require('@/assets/images/landing/bunny/bunny2.webp'),
          medium: require('@/assets/images/landing/bunny/bunny2.webp'),
          small: require('@/assets/images/landing/bunny/bunny2.webp')
        }
      }

      this.createStar = require('@/assets/images/CaStar.svg')
      this.ratingStar = require('@/assets/images/landing/rating.png')
    },
    methods: {
      onCoursePurchaseActionClick(actionType) {
        this.trackEvent('course_purchase_action', {
          userType: this.getUserType(),
          action: actionType,
          course: 'The Ultimate Guide to Interior Design',
          abTest: this.activeAbTest(this.targetTest) // associate this event with the active AB test
        })
      }
    }
  }
</script>
