<template>
  <div class="container mx-auto px-4 py-6 2xl:py-8">
    <MetaTags :metaTags="metaTags" />
    <!-- Title & Subhead -->
    <div class="text-center px-4 md:px-0 mb-6">
      <div class="title-largest mb-1">Customise your gift</div>
    </div>

    <div
      class="flex flex-col justify-center mb-0 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto max-w-2xl"
    >
      <GiftForm
        :campaigns="campaigns"
        :preSelectedCampaign="preSelectedCampaign"
        :gtmPageName="currentPage"
      />

      <!-- <div class="w-full flex flex-col gap-2">
        <div class="relative w-full">
          <Radio
            testid="customise-gifts-email-self"
            option="self"
            label="Send gift email to me"
            class="text-blue-800 text-lg tracking-wide font-normal radio-m"
          />
        </div>
        <div class="relative w-full">
          <Radio
            testid="customise-gifts-email-self"
            option="self"
            label="Send gift email to recipient"
            class="text-blue-800 text-lg tracking-wide font-normal radio-m"
          />
        </div>
        <div class="relative w-full mt-10">
          <InputGroup
            testid="customize-gifts-input-recipient-name"
            label="recipient name"
            placeholder="Name"
          />
        </div>
        <div class="relative w-full">
          <InputGroup
            testid="customize-gifts-input-recipient-email"
            label="recipient email"
            placeholder="Email"
          />
        </div>
        <div class="relative w-full">
          <InputGroup
            testid="customize-gifts-input-your-name"
            label="who is the gift from?"
            placeholder="You can write you name here"
          />
        </div>
        <div class="relative w-full">
          <TextArea
            testid="customize-gifts-input-meesage"
            :maxLength="250"
            label="gift message"
            placeholder="Your message will be written on a gift-card email"
          />
        </div>
        <div
          class="mx-auto text-center w-full mt-8 mb-6 flex justify-center items-center flex-wrap gap-x-6 gap-y-4"
        >
          <button
            type="submit"
            class="button w-full sm:w-auto button--light-blue-outline"
          >
            add to basket
          </button>
          <button type="submit" class="button w-full sm:w-auto button--blue">
            preview your gift
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'
import MetaTags from '@/Components/Shared/MetaTags'
// import Radio from '@/Components/Shared/Forms/Radio'
// import InputGroup from '@/Components/Shared/Forms/InputGroup'
// import TextArea from '@/Components/Shared/Forms/TextArea'
import GiftForm from '@/Components/Shared/Gifts/Form'

export default {
  mixins: [metaTags],

  components: {
    MetaTags,
    // Radio,
    // InputGroup,
    // TextArea,
    GiftForm
  },

  props: {
    campaigns: {
      type: Array,
      default: () => []
    },
    preSelectedCampaign: {
      type: String,
      required: true
    }
  },

  computed: {
    currentPage() {
      return 'Gifts'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
  }
}
</script>
