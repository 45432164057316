<template>
  <div class="py-8">
    <h2 class="w-full flex-grow overline--medium text-yellow-800">Manage Your Subscription</h2>

    <div class="mt-4 grid grid-cols-2 gap-y-2 font-medium">
      <div>Your Current Plan</div>
      <div class="text-right capitalize">{{ membershipPlan.slug }} Plan</div>

      <div>Price</div>
      <div class="text-right">£{{ planRate }}</div>
    </div>

    <div v-if="showCancelButton" class="mt-4 flex">
      <p
        data-testid="membership-cancel-button"
        @click="cancelMembership"
        class="flex items-center justify-center gap-2 border-b-2 border-blue-500 cursor-pointer text-base font-normal tracking-wider text-blue-900 hover:border-blue-900"
        :class="[processing && 'pointer-events-none']"
      >
        <span>Cancel Subscription</span>
        <svg
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33333 1.625L11.5 6M11.5 6L7.33333 10.375M11.5 6L1.5 6"
            stroke="#18336C"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import mixpanel from '@/mixins/mixpanel'

  export default {
    mixins: [mixpanel],

    props: {
      membershipPlan: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        processing: false
      }
    },

    computed: {
      planRate() {
        if (this.membershipPlan.slug == 'lifetime') {
          return this.membershipPlan.price
        } else if (this.membershipPlan.slug == 'annual') {
          return `${this.membershipPlan.price}/Year`
        }

        return `${this.membershipPlan.price}/Month`
      },

      showCancelButton() {
        return (
          this.membershipPlan.can_cancel &&
          !this.membershipPlan.cancelled_at &&
          ['active', 'grace_period'].includes(this.membershipPlan.status.toLowerCase()) &&
          this.membershipPlan.slug !== 'lifetime'
        )
      }
    },

    methods: {
      async cancelMembership() {
        try {
          this.processing = true

          // Use the mixpanel mixin to track the event
          this.trackEvent('Cancel Subscription Clicked', {
            plan: this.membershipPlan.slug,
            status: this.membershipPlan.status
          })

          const response = await axios.post('/membership/cancel')

          if (response.status == 200) {
            window.churnkey.init('show', response.data.data)
          } else {
            console.error('Failed to fetch')
          }
        } catch (error) {
          console.error('Error fetching:', error)
        } finally {
          this.processing = false
        }
      },

      trackCancelButtonDisplay() {
        if (this.showCancelButton) {
          this.trackEvent('Cancel Subscription Button Displayed', {
            plan: this.membershipPlan.slug,
            status: this.membershipPlan.status
          })
        }
      }
    },

    mounted() {
      this.trackCancelButtonDisplay()
    },

    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
