<template>
  <!-- class="flex flex-col lg:flex-row items-stretch bg-blue-500 mt-14" -->
  <div
    data-testid="access-all-course-banner"
    :class="[
      'flex',
      'flex-col',
      'lg:flex-row',
      'items-stretch',
      'mt-14',
      'bg-blue-500',
    ]"
  >
    <!-- Image Block - First for smaller screens, remains on the right for lg and above -->
    <div
      class="cat-image w-full lg:w-1/2 bg-opacity-20 banner-image order-first lg:order-last"
    >
      <img
        loading="lazy"
        v-if="!customImage"
        data-testid="banner-image"
        :id="`banner-image-${category.slug}`"
        :alt="`${imageAlt}`"
        :src="!lazySlide ? bannerImage.url : null"
        :data-src="lazySlide ? bannerImage.url : null"
        :srcset="!lazySlide ? bannerImage.srcset : null"
        :data-srcset="lazySlide ? bannerImage.srcset : null"
        class="w-full h-full object-cover object-center"
        :class="classNames"
      />
      <img
        loading="lazy"
        v-else
        alt
        class="w-full h-full object-cover object-center"
        :src="
          imageUrl
            ? imageUrl
            : require('@/assets/images/membership_banner.webp')
        "
      />
    </div>

    <!-- Text Content - Second for smaller screens, remains on the left for lg and above -->
    <div
      class="banner-content flex flex-col justify-center text-left py-16 gap-6 w-full lg:w-1/2 px-6 md:px-12 mb-12 lg:mb-0"
    >
      <div class="mb-2">
        <div class="mb-1 title-title text-5xl text-white font-serif">
          Access All Courses
        </div>
        <div
          data-testid="monthly-price"
          class="font-normal text-lg text-white font-sans"
        >
          {{ getGeoPrice(membershipPlan.monthlyPrice) }}/MONTH (billed annually)
        </div>
      </div>
      <div
        v-for="(point, index) in membershipBannerPoints"
        :key="index"
        class="flex flex-row items-top font-normal text-left"
      >
        <Icon name="Check" class="text-white text-lg mr-3 mt-1" />
        <p class="text-white text-xl">{{ point }}</p>
      </div>

      <div class="mt-2 text-left">
        <div class="inline-block text-center">
          <Link
            class="button button--yellow inlineBlockBtn"
            data-testid="start-learning-button"
            @click="trackSubscribeButton"
            :href="$routes.onboarding()"
          >
            <!-- <span>Join for {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span> -->
            <span class="flex gap-1 items-center"
              >START LEARNING
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 5L20 12M20 12L13.3333 19M20 12L4 12"
                  stroke="#F6F7F9"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'
import Icon from '@/Components/Shared/Icon'
import imgSrcset from '@/mixins/imgSrcset'
import monetize from '@/mixins/monetize'
import { Link } from '@inertiajs/inertia-vue'

export default {
  mixins: [mixpanel, imgSrcset, monetize],

  components: {
    Icon,
    Link
  },

  props: {
    category: {
      type: Object,
      required: true
    },

    membershipPlan: {
      type: Object,
      default: null
    },

    geoProps: {
      type: Object,
      required: true
    },

    customImage: {
      type: Boolean,
      default: false
    },

    component: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    }
  },

  computed: {
    membershipBannerPoints() {
      return [
        'Unlimited access to all courses and resources',
        'Over 1,000 immersive lessons to transform the way you live',
        'Expert instruction from the best designers, makers, growers and chefs',
        'New content added each month to help you develop your skills'
      ]
    },

    bannerImage() {
      if (this.category.secondaryBannerImagePath) {
        return this.imgSrcsetBanner(this.category.secondaryBannerImagePath)
      } else {
        return { url: null, srcset: [] }
      }
    }
  },

  methods: {
    getGeoPrice(gbpBasePrice) {
      const decimalPlaces = this.geoProps.currency === 'USD' ? 0 : 2

      return this.geoMonetize(
        gbpBasePrice * this.geoProps.exchangeRate,
        this.geoProps.currencySymbol,
        decimalPlaces
      ).replace(/\.00$/, '')
    },

    trackSubscribeButton() {
      this.trackEvent('subscribe_button', {
        userType: this.currentUser ? 'authed' : 'anonymous',
        page: window.location.pathname,
        component: this.component
      })
    }
  }
}
</script>
