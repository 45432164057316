<template>
  <div class="custom-video-player relative">
    <div v-if="!isPlaying" class="absolute inset-0 z-10">
      <img
        :src="require('@/assets/images/landing/bunny/bunny-image-video.jpeg')"
        alt="video_thumbnail"
        class="w-full h-full object-cover"
      />
      <div
        @click="triggerPlay"
        class="absolute left-1/2 transform -translate-x-1/2 bg-white py-3 px-4 lg:px-8 flex items-center gap-2 cursor-pointer w-4/5 lg:w-auto justify-center"
        style="bottom: 2rem"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 2L21 11.9447L5 22V2ZM5.91725 3.65011V20.3402L19.2693 11.949L5.91725 3.65011Z"
            fill="#0E1E38"
          />
        </svg>
        <span class="text-base text-blue-800 font-normal tracking-wide">WATCH TRAILER</span>
      </div>
    </div>

    <BrightcovePlayer
      class="video-ratio-16-9"
      v-bind="$attrs"
      @onPlaying="handlePlaying"
      ref="brightcovePlayer"
      @onWaiting="onWaiting"
      @onPlay="onPlay"
      @onAutoPlay="onAutoPlay"
      @onPause="handlePause"
      @onEnded="onEnded"
      @onFullscreenChange="onFullscreenChange"
      @onTimeupdate="onTimeupdate"
      @onCanPlayThrough="onCanPlayThrough"
      @onError="onError"
    />
  </div>
</template>

<script>
  import throttle from 'lodash/throttle'
  import mixpanel from '@/mixins/mixpanel'
  import BrightcovePlayer from '@/vendor/brightcove/BrightcovePlayer'

  const VIDEO_ACTION_EVENT = 'video_action'
  const VIDEO_EVENT = 'video_event'
  const VIDEO_STREAMED_EVENT = 'video_streamed'
  const VIDEO_PROGRESS_EVENT = 'video_progress'
  const VIDEO_ERROR_EVENT = 'video_error'
  const PROGRESS_TRACKING_POINTS = [75, 50, 25]

  export default {
    mixins: [mixpanel],

    components: {
      BrightcovePlayer
    },

    props: {
      mixpanel: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        isPlaying: false,
        showThumbnail: true
      }
    },

    methods: {
      triggerPlay() {
        this.isPlaying = true
        this.$refs.brightcovePlayer.play()
      },

      handlePlaying() {
        this.isPlaying = true
        this.onPlaying()
      },

      handlePause() {
        this.isPlaying = false
        this.onPause()
      },

      onTimeupdate: throttle(function (e, player) {
        let currentTime
        let duration
        try {
          currentTime = player?.currentTime()
          duration = player?.duration()
        } catch (e) {
          if (e instanceof TypeError) {
            return
          } else {
            throw e
          }
        }

        if (!currentTime || !duration) return
        this.$emit('onTimeupdate', currentTime)

        const progress = this.calcPercentage(currentTime, duration)

        if (this.previousProgress === null) {
          const { autoPlayAt } = this.$attrs
          this.previousProgress = this.calcPercentage(autoPlayAt || 0, duration)
        }

        for (const each of PROGRESS_TRACKING_POINTS) {
          if (this.previousProgress < each && progress >= each) {
            this.trackVideoProgress(each)
            break
          }
        }

        this.previousProgress = progress
      }, 5000),

      calcPercentage(numerator, denominator) {
        return Math.round((numerator / denominator) * 100)
      },

      onFullscreenChange(e) {
        const isFullscreen = e.target.classList.contains('vjs-fullscreen')
        this.trackVideoAction(isFullscreen ? 'fullscreen' : 'inline')
      },

      onPlaying() {
        this.trackVideoEvent('playing')
        this.startTimeEvent()
      },

      onWaiting() {
        this.trackVideoEvent('waiting')
        this.trackVideoStreamed({ previousEvent: 'waiting' })
      },

      onPlay(e, player) {
        this.trackVideoAction('play')
        this.$emit('onPlay', player)
      },

      onAutoPlay(e, player) {
        this.trackVideoAction('auto_play')
        this.$emit('onAutoPlay', player)
      },

      startTimeEvent() {
        this.timeEvent(VIDEO_STREAMED_EVENT)
        this.streaming = true
      },

      onPause() {
        this.trackVideoAction('pause')
        this.trackVideoStreamed({ previousEvent: 'pause' })
      },

      onEnded(e, player) {
        this.trackVideoProgress(100)
        this.$emit('onEnded', player)
      },

      onCanPlayThrough(e, player) {
        this.$emit('onCanPlayThrough', player)
      },

      onError(type = 'error') {
        this.trackEvent(VIDEO_ERROR_EVENT, { ...this.mixpanel, type })
      },

      trackVideoProgress(percentage) {
        this.trackEvent(VIDEO_PROGRESS_EVENT, { ...this.mixpanel, percentage })
        this.$emit('onTrackVideoProgress', percentage)
      },

      trackVideoStreamed({ previousEvent = 'unload' }) {
        if (this.streaming) {
          this.streaming = false
          this.trackEvent(VIDEO_STREAMED_EVENT, { ...this.mixpanel, previousEvent })
        }
      },

      trackVideoEvent(type) {
        this.trackEvent(VIDEO_EVENT, { ...this.mixpanel, type })
      },

      trackVideoAction(type) {
        this.trackEvent(VIDEO_ACTION_EVENT, { ...this.mixpanel, type })
      }
    }
  }
</script>

<style scoped>
  .custom-video-player {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .video-ratio-16-9 {
    width: 100%;
    height: 100%;
  }
</style>
