<template>
  <div class="py-8">
    <h2 class="w-full flex-grow overline--medium text-yellow-800">Current Subscription Details</h2>

    <div v-if="membershipPlan" class="mt-4 grid grid-cols-2 gap-y-2 font-medium">
      <div>Subscription Plan</div>
      <div class="text-right capitalize">{{ membershipPlan.slug }} Plan</div>

      <div>Subscription Status</div>
      <div class="text-right">{{ membershipPlan.status }}</div>

      <div>Start Date</div>
      <div class="text-right">{{ membershipPlan.start_date }}</div>

      <template v-if="membershipPlan.expiry_date">
        <div>Renewal Date</div>
        <div class="text-right">{{ membershipPlan.expiry_date }}</div>
      </template>

      <template v-if="membershipPlan.cancelled_at">
        <div>Cancelled Date</div>
        <div class="text-right">{{ membershipPlan.cancelled_at }}</div>
      </template>
    </div>

    <div v-else>
      <p data-testid="no-membership" class="my-10 text-lg text-opacity-80">
        {{ $I18n.t('no_membership', i18nScope) }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      membershipPlan: {
        type: Object,
        default: null
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
