var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'flex-col',
    'lg:flex-row',
    'items-stretch',
    'backgroundState',
    'bg-neutral-200',
    'md:bg-transparent'
  ],style:({ backgroundImage: `url(${_vm.imageUrl})` }),attrs:{"data-testid":"access-all-course-banner"}},[_c('div',{staticClass:"container py-14 lg:py-36 relative"},[_c('div',{staticClass:"banner-content flex flex-col justify-center text-left gap-2 lg:gap-4 w-full lg:w-112 lg:mb-0"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"mt-2 lg:mt-4 text-left"},[_c('div',{staticClass:"inline-block text-center w-full xs:w-auto"},[_c('Link',{staticClass:"button button--red inlineBlockBtn w-full xs:w-96 max-w-full",attrs:{"data-testid":"start-learning-button","href":"/free-lessons"}},[_c('span',{staticClass:"flex gap-1 items-center justify-center"},[_vm._v("WATCH A FREE LESSON\n            ")])])],1)])])]),_vm._v(" "),_c('div',{staticClass:"block md:hidden"},[_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.respimageUrl,"alt":"banner mobile image"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"flex items-center justify-start gap-4 mb-5"},[_c('img',{staticClass:"h-3.5 lg:h-5",attrs:{"src":require("@/assets/images/backgrounds/banner/trustpilot.png"),"alt":"trustpilot rating"}}),_vm._v(" "),_c('p',{staticClass:"text-sm lg:text-xl font-normal text-black"},[_vm._v("Rated "),_c('span',{staticClass:"font-medium"},[_vm._v("4.8/5")]),_vm._v(" on Trustpilot")])]),_vm._v(" "),_c('div',{staticClass:"mb-2 lg:mb-4 font-medium text-3xl lg:text-4xl text-black font-serif"},[_vm._v("\n          Want To See What All The Fuss Is About?\n        ")]),_vm._v(" "),_c('div',{staticClass:"font-normal text-base lg:text-xl text-black",attrs:{"data-testid":"monthly-price"}},[_vm._v("\n        We’ve chosen some of our very best lessons for you to take a peek at. Enjoy!\n        ")])])
}]

export { render, staticRenderFns }