<template>
    <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z[999]"
        @click="handleBackdropClick">
        <div class="bg-neutral-100 w-[712px] h-[711px] overflow-y-auto sm:overflow-hidden">
            <img :src="headerImage" alt="Subscription Offer" class="w-full h-48 object-cover">
            <div class="py-6 px-4 sm:py-10 sm:px-8 md:px-16">
                <h2 class="offer-title text-3xl sm:text-[40px] sm:leading-[36px]">Subscribe to Create Academy</h2>
                <p class="mb-4 sm:mb-6 text-base sm:text-lg offer-subtitle mt-2 sm:mt-6 md:mt-2">
                    Instead of one course, access over 40 for just <strong>{{
                        membershipPlan.geoPriceDetails.monthlyPrice
                        }}/month</strong>
                </p>


                <div class="mb-6 sm:mb-0">
                    <ul class="space-y-3 sm:space-y-4 benefits-list text-left">
                        <li v-for="(benefit, index) in benefits" :key="index" class="flex items-start">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black mr-2 mt-1 flex-shrink-0"
                                viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span>{{ benefit }}</span>
                        </li>
                    </ul>
                </div>

                <div class="mt-6 sm:mt-auto sm:px-14 space-y-3 sm:space-y-4 flex flex-col">
                    <button @click="handleSubscribe" class="offer-button offer-button-bg-primary w-full sm:w-[300px]">
                        SUBSCRIBE NOW
                    </button>
                    <button @click="handleDecline" class="offer-button offer-button-bg-secondary w-full sm:w-[300px]">
                        NO THANKS
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpsellOfferModal',
    props: {
        membershipPlan: {
            type: Object,
            required: true
        },
        headerImage: {
            type: String,
            required: true
        }
    },
    computed: {
        saleReduction() {
            return this.membershipPlan && this.membershipPlan?.discountPercent ? true : false
        }
    },
    data() {
        return {
            isVisible: false,
            benefits: [
                'Unlimited access to all courses and resources',
                'Over 1000 immersive lessons to nurture your creativity',
                'Expert instruction from the best designers, makers, growers and chefs',
                'New content added each month to help you develop your skills'
            ]
        }
    },
    methods: {
        handleBackdropClick(event) {
            if (event.target === event.currentTarget) {
                this.hide()
            }
        },
        show() {
            this.isVisible = true
            this.$emit('show')
        },
        hide() {
            this.isVisible = false
            this.$emit('hide')
        },
        handleSubscribe() {
            this.hide()
            this.$emit('accept-upsell-offer')
        },
        handleDecline() {
            this.hide()
            this.$emit('decline-upsell-offer')
        }
    }
}
</script>

<style scoped>
.fixed {
    z-index: 9999 !important;
}

.bg-navy-blue {
    background-color: #18336C;
}

.text-navy-blue {
    color: #18336C;
}

.offer-title {
    display: flex;
    height: 42.915px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--blue-800, #0E1E38);
    text-align: center;
    font-family: "Saol CA";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}

.offer-subtitle {
    align-self: stretch;
    color: var(--blue-800, #0E1E38);
    text-align: center;
    font-family: "Brandon Grotesque";
    font-size: 22px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
}

.offer-subtitle strong {
    color: var(--blue-800, #0E1E38);
    font-family: "Brandon Grotesque";
    font-size: 22px;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
}

.offer-header-image {
    width: 712px;
    height: 247px;
    flex-shrink: 0;
}

.offer-button {
    width: 300px;
    padding: var(--Main-System-12px, 12px) var(--Main-System-24px, 24px);
    justify-content: center;
    align-self: center;
    align-items: center;
    gap: var(--Main-System-8px, 8px);
}

.offer-button-bg-primary {
    color: white;
    background: var(--blue-500, #18336C);
    border: 1px solid var(--blue-800, #0E1E38);
}

.offer-button-bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 235, 240, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(232, 235, 240, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(24, 51, 108, var(--tw-text-opacity));
}

.offer-button-bg-secondary {
    color: var(--blue-800, #0E1E38);
    border: 1px solid var(--blue-800, #0E1E38);
    background: #FFF;
}

.offer-button-bg-secondary:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.benefits-list {
    margin-left: 1.0rem;
    margin-bottom: 2.5rem;
}

.benefits-list li {
    color: #000;
    font-family: "Brandon Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
}

@media (max-width: 639px) {
    .offer-title {
        font-size: 30px;
        line-height: 1.2;
        height: auto;
    }

    .offer-subtitle {
        font-size: 18px;
    }

    .offer-subtitle strong {
        font-size: 18px;
    }

    .benefits-list {
        margin-left: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .benefits-list li {
        font-size: 14px;
    }

    .offer-button {
        width: 100%;
    }


}
</style>