<!-- VerticalVideo.vue -->
<template>
  <div :id="containerUniqueId" class="w-full h-full">
    <video-js
      :id="playerUniqueId"
      :data-video-id="videoId"
      :data-account="accountId"
      :data-player="playerId"
      data-embed="default"
      data-application-id
      controls
      playsinline
      class="w-full h-full object-cover video-js-custom"
    ></video-js>
  </div>
</template>

<script>
  export default {
    name: 'VerticalVideo',
    props: {
      videoId: {
        type: String,
        required: true
      },
      accountId: {
        type: String,
        required: true
      },
      playerId: {
        type: String,
        required: true
      },
      uniqueId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        player: null,
        containerUniqueId: `container-${this.uniqueId}`,
        playerUniqueId: `player-${this.uniqueId}`
      }
    },
    mounted() {
      this.loadVideoJsScript().then(() => {
        this.initializePlayer()
      })
    },
    methods: {
      loadVideoJsScript() {
        return new Promise(resolve => {
          if (window.bc && window.videojs) {
            resolve()
            return
          }
          const script = document.createElement('script')
          script.src = `//players.brightcove.net/${this.accountId}/${this.playerId}_default/index.min.js`
          script.onload = resolve
          document.head.appendChild(script)
        })
      },
      initializePlayer() {
        this.$nextTick(() => {
          if (window.bc && window.videojs) {
            this.player = window.bc(this.playerUniqueId)
            this.setupPlayerEvents()
          } else {
            console.error('Brightcove player is not defined')
          }
        })
      },
      setupPlayerEvents() {
        if (this.player) {
          this.player.on('play', () => {
            this.player.muted(false)
            this.player.volume(1)
            this.$emit('video-play')
          })

          this.player.on('loadedmetadata', () => {
            this.player.muted(false)
            this.player.volume(1)
          })
        }
      }
    }
  }
</script>

<style scoped>
  .video-js-custom .vjs-big-play-button {
    background-color: rgba(0, 0, 0, 0.45) !important;
    transition: background-color 0.3s ease !important;
  }

  .video-js-custom:hover .vjs-big-play-button,
  .video-js-custom .vjs-big-play-button:focus {
    background-color: rgba(229, 229, 229, 0.8) !important; /* bg-neutral-200 with 80% opacity */
  }

  .video-js-custom .vjs-play-progress,
  .video-js-custom .vjs-volume-level {
    background-color: rgba(229, 229, 229, 0.8) !important; /* bg-neutral-200 with 80% opacity */
  }

  .video-js-custom .vjs-play-control:hover,
  .video-js-custom .vjs-volume-panel:hover,
  .video-js-custom .vjs-fullscreen-control:hover {
    color: rgba(229, 229, 229, 0.8) !important; /* bg-neutral-200 with 80% opacity */
  }

  .video-js-custom .vjs-progress-holder {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .video-js-custom .vjs-load-progress {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .video-js-custom .vjs-slider-bar {
    background-color: rgba(229, 229, 229, 0.8) !important; /* bg-neutral-200 with 80% opacity */
  }
</style>
